import "./styles/Team.css";
import teamIcon from "../../../assets/icon_equipo.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";
import { Link, useParams } from "react-router-dom";
import useGetFirstUrlParam from "../../../hooks/useGetFirstUrlParam";

const DropdownIcon = () => <img src={teamIcon} alt="Icon Equipo" />;

interface Studies {
	pais: string;
	escuela: string;
	estudio: string;
	institucion: string;
}
interface TeamMember {
	rut: string;
	investigador: string;
	tipo_investigador: string | null;
	existe: number;
	estudios: Studies[];
	id: number;
}
interface Props {
	teamMembers: TeamMember[];
	university: string;
}
const Team = ({ teamMembers }: Props): JSX.Element => {
	const getStudies = (studies: Studies[]): string => {
		if (!studies) {
			return "";
		}
		let studiesString: string = "";
		studies.forEach(({ estudio }, index: number) => {
			if (index > 0) studiesString += ", ";
			studiesString += estudio;
		});
		return studiesString;
	};

	const urlLocation = useGetFirstUrlParam();

	const { university: universidad } = useParams<{
		university: string;
	}>();

	return (
		<DropDownWithIcon title={"EQUIPO"} icon={DropdownIcon}>
			<div className="dropdown-information-perfil-investigador dropdown-information-perfil-investigador information-grid">
				<div className="container-profile-equipo">
					{teamMembers.map(({ investigador, existe, estudios, id, rut, tipo_investigador }: TeamMember) => (
						<div className="profile-equipo" key={id}>
							<div className="container-image-profile-equipo">
								<img
									className="image-profile-equipo"
									src={`/avatar/${id}.jpg`}
									alt="Equipo imagen"
									onError={(e: any) => {
										e.target.onerror = null;
										e.target.style.width = "100%";
										e.target.src =
											"https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fzancada.com%2Fwp-content%2Fimagenes%2F2012%2F12%2Ffacebook-profile-picture-n-copy.jpg&f=1&nofb=1";
									}}
								/>
							</div>
							<div className="container-text-profile-equipo">
								{existe && id ? (
									<Link
										className="name-profile-equipo"
										to={`/${urlLocation}/profile/reseacher/${universidad}/${id}`}
									>
										{investigador}
									</Link>
								) : (
									<p className="name-profile-equipo-does-not-exist">{investigador}</p>
								)}
								<p className="text-profile-equipo">{getStudies(estudios)}</p>
								{!existe && tipo_investigador && <p className="text-profile-equipo">{tipo_investigador}</p>}
							</div>
						</div>
					))}
				</div>
			</div>
		</DropDownWithIcon>
	);
};

export default Team;
