import { BrowserRouter as Router,Redirect,Route, Switch } from "react-router-dom"
import App from "./Principal"

export const WrapperRouter = () => {
  return (
    <Router>
          <Switch>
            <Route path="/:university" component={ App } />
            <Redirect to="/caps/" />  
          </Switch>    
    </Router>
  )
}
