

import { ChildPrivacity } from "./policies/ChildPrivacity";
import { DataDivulgation } from "./policies/DataDivulgation";
import { DataRetention } from "./policies/DataRetention";
import { DataSecurity } from "./policies/DataSecurity";
import { ExercisesDataOwner } from "./policies/ExercisesDataOwner";
import { InternationalTransferPersonalData } from "./policies/InternationalTransferPersonalData";
import { InterpretationAndDefinitions } from "./policies/InterpretationAndDefinitions";
import { PolicyChanges } from "./policies/PolicyChanges";
import { RecopilationsAndPersonalData } from "./policies/RecopilationsAndPersonalData";
import "./PrivacyPoliciesBox.css";

export const PrivacyPoliciesBox = () => {

	return (
        <div className="privacy-components-container">
            <div className="headerPrivacy">
                <u className="title-politica">Política de Privacidad de HUBTEC</u>
                <span >
                    <p className="subtitle-politica" >Objetivo de esta política</p>
                </span>
                <p>
                    Este documento de privacidad describe nuestras políticas y procedimientos sobre
                la recopilación, uso y divulgación de su información cuando usa nuestro sitio web y
                le informa sobre sus derechos relativos a la protección de datos a que se refiere la
                ley 19.628
                </p>
                <span >
                    <p className="subtitle-politica" >Finalidad de la misma</p>
                </span>
                <p>
                    Nosotros usamos sus datos personales para robustecer la información y mejorar
                    nuestro sitio web, pero también para brindar mas oportunidades a los miembros de
                    nuestra comunidad y para los usos indicados en punto recopilación y uso de datos
                    letra e) Al utilizar La plataforma, acepta la recopilación y el uso de información de
                    acuerdo con esta Política de privacidad.
                </p>

                <span >
                    <p className="subtitle-politica" >Contáctanos</p>

                </span>
                <p>
                    Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:

                </p>
                <ul>
                    <li>Por correo eletrónico contacto@hubtec.cl</li>
                </ul>
            </div>
            
            
            <InterpretationAndDefinitions  />

            <RecopilationsAndPersonalData />

            <DataRetention />
            
            <InternationalTransferPersonalData />

            <DataDivulgation />

            <DataSecurity />

            <ChildPrivacity />

            <PolicyChanges />

            <ExercisesDataOwner />
        



                

        </div>
        

    )

};
