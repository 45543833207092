import TagManager from "react-gtm-module";
import { Link, useParams, useLocation } from "react-router-dom";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import "./styles/Register.css";
import React, { useState, useContext } from 'react';
import queryString from "query-string";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Slide, Stack } from "@mui/material";
import { IconVisibility, IconVisibilityOff } from "@aws-amplify/ui-react";
import { validarCambioPassword } from "global/utils/validators";
import { createTheme } from "@mui/material/styles";
import { blue, orange } from '@mui/material/colors';
import { TransitionProps } from '@mui/material/transitions';
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../../../aws-config';
import { UserContext, Login } from '../../login/container/UserContext'

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
	palette: {
		primary: orange,
		secondary: blue
	}
});

interface University {
	id: number;
	name: string;
	acronym: string
}

Amplify.configure(awsconfig);


export const Changepass = () => {
	const { university = "" } = useParams<{ university: string }>();
	TagManager.dataLayer({
		dataLayer: {
			event: "visita",
			pagePath: "Cambiarpassword",
			university: translateUniversityAcronym(university) || university,
		},
	});
	const [showPasswordOld, setShowPasswordOld] = useState(false);
	const handleClickShowPasswordOld = () => setShowPasswordOld((show) => !show);
	const handleMouseDownPasswordOld = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
	const handleMouseDownPasswordConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const [passwordOld, setPasswordOld] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showErrors, setShowErrors] = useState<any>({ state: true, errors: [] })

	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false);
	const [loginError, setLoginError] = useState('');

	const handleCloseModal = () => {
		setOpen(false);
	};
	const location = useLocation();


	const firstParam = useGetFirstUrlParam();

	const userContext = useContext(UserContext);

	const handleChange = async () => {
		try {
			setLoading(true)
			setShowErrors(validarCambioPassword({ passwordOld, password, confirmPassword }));
			if (!showErrors.state) {
				return;
			}
			////////
			const login = userContext.user;
			//const login = await Auth.signIn(user, passwordOld);
			//const login = await Auth.currentAuthenticatedUser();

			if ((login as any)?.challengeName === 'NEW_PASSWORD_REQUIRED') {
				const user = await Auth.signIn((login as any)?.username, passwordOld);
				const loggedInUser = await Auth.completeNewPassword(
					user, // the Cognito User Object
					password, // the new password
				)
			} else {
				const data = await Auth.changePassword(login, passwordOld, password);
			}
			await Auth.signOut();
			userContext.user=null;
			setLoginError('');
			///////
			setLoading(false)
			console.log(showErrors)
			setOpen(true);
		} catch (error) {
			console.error("Ha ocurrido un error: ", error)
			setLoginError(processChangePasswordError(error));
		}
	};

	const processChangePasswordError = (error: any): string => {
		switch (error?.code) {
			case 'NotAuthorizedException':
				return 'Credenciales inválidas. Por favor, verifica tu contraseña actual.';
			case 'PasswordResetRequiredException':
				return 'Debes restablecer tu contraseña antes de poder cambiarla.';
			case 'CodeMismatchException':
				return 'El código de confirmación proporcionado no es válido.';
			case 'LimitExceededException':
				return 'Has excedido el límite de intentos. Por favor, espera un momento antes de intentar nuevamente.';
			case 'PasswordResetRequiredException':
				return 'Debes restablecer tu contraseña antes de poder cambiarla.';
			case 'CodeMismatchException':
				return 'El código de confirmación proporcionado no es válido.';
			case 'LimitExceededException':
				return 'Has excedido el límite de intentos. Por favor, espera un momento antes de intentar nuevamente.';
			case 'ExpiredCodeException':
				return 'El código de confirmación ha expirado. Por favor, solicita uno nuevo.';
			case 'InvalidPasswordException':
				return 'La contraseña debe contener números, caracteres especiales, mayúsculas y minúsculas.';
			default:
				return 'Ha ocurrido un error inesperado. Por favor, intenta nuevamente.';
		}
	};
	return (
		<div style={{ backgroundImage: `url("/images/caps/Cubo_hubtec.svg")`, backgroundRepeat: "no-repeat", width: "80%", margin: "0px auto", maxWidth: "900px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", }}>
			<Box
				className="home-component"
				sx={{ width: 400, marginTop: 3, marginBottom: 5, backgroundColor: "transparent" }}
			>
				<p>CaPS es un programa de transformaci&oacute;n de capacidades de I+D en productos y servicios que respondan a necesidades de la industria.</p>
			</Box>
			<Box
				className="home-component"
				sx={{ width: 400, marginTop: 3, marginBottom: 5, backgroundColor: "white" }}
			>
				<div className="registration-container">
					<h2>Cambio de contraseña</h2>

					<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
						<InputLabel htmlFor="outlined-adornment-password">Contraseña actual</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={showPasswordOld ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPasswordOld}
										onMouseDown={handleMouseDownPasswordOld}
										edge="end"
									>
										{showPasswordOld ? <IconVisibilityOff /> : <IconVisibility />}
									</IconButton>
								</InputAdornment>
							}
							label="Contraseña actual"
							value={passwordOld}
							onChange={(e) => setPasswordOld(e.target.value)}
						/>
					</FormControl>
					{/*
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
						<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined css-1jy569b-MuiFormLabel-root-MuiInputLabel-root" >
							La contraseña nueva debe contener números, caracteres
						</label>
					</FormControl>*/}
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
						<label className="label-pass" >La contraseña nueva debe contener números, caracteres especiales, mayúsculas y minúsculas</label>
					</FormControl>

					<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
						<InputLabel htmlFor="outlined-adornment-password">Contraseña nueva</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <IconVisibilityOff /> : <IconVisibility />}
									</IconButton>
								</InputAdornment>
							}
							label="Contraseña nueva"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</FormControl>
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
						<InputLabel htmlFor="outlined-adornment-password">Confirmar contraseña nueva</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={showPasswordConfirm ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPasswordConfirm}
										onMouseDown={handleMouseDownPasswordConfirm}
										edge="end"
									>
										{showPasswordConfirm ? <IconVisibilityOff /> : <IconVisibility />}
									</IconButton>
								</InputAdornment>
							}
							label="Confirmar contraseña nueva"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</FormControl>






					{
						!showErrors.state ?
							<>
								{showErrors.errors.map((error: any) => {
									return <p style={{ color: "red" }}>*{error.message}</p>
								})}
							</> :
							<></>
					}
					{
						loginError === '' ?
							<></> :
							<p style={{ color: "red" }}>*{loginError}</p>
					}
					<Stack spacing={1} direction="row" justifyContent="center" marginTop={2}>
						<Button variant="contained" onClick={handleChange} style={{ backgroundColor: "#FE894A" }}>Cambiar</Button>
					</Stack>
				</div>

				<Dialog
					open={open && showErrors.state}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleCloseModal}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle>{"Confirmación de cambio de contraseña"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Para poder ingresar debes usar tu correo electr&oacute;nico y la nueva contraseña.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Link to={`/${firstParam}/`} onClick={handleCloseModal}>
							<Button>Ir a la pagina principal</Button>
						</Link>
						<Link to={`/${firstParam}/ingresar`} onClick={handleCloseModal}>
							<Button>Ingresar a la plataforma</Button>
						</Link>
					</DialogActions>

				</Dialog>

			</Box>

		</div>
	);
};