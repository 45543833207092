import "./styles/Partners.css";
import partnersIcon from "../../../assets/icon_proyectos.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";

const DropdownIcon = () => <img src={partnersIcon} alt="Icon Partners" />;

interface Partner {
	partner: string;
}
interface Props {
	partners: Partner[];
}

export const Partners = ({ partners }: Props): JSX.Element => {
	return (
		<DropDownWithIcon title={"PARTNERS"} icon={DropdownIcon}>
			<div className="dropdown-information-perfil-investigador dropdown-information-perfil-investigador information-grid">
				{partners.map(({ partner }: Partner, index: number) => (
					<p className="text-dropdown-generic" key={partner + index}>
						• {partner}
					</p>
				))}
			</div>
		</DropDownWithIcon>
	);
};
