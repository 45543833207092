import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import Password from '@mui/icons-material/Password';
import awsconfig from '../../../aws-config';
import { Amplify, Auth } from 'aws-amplify';
import { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";
import { UserContext, Login } from './UserContext'

Amplify.configure(awsconfig);

interface Props {
  url: string;
  folder: string;
}

export const AccountMenu = ({ url, folder }: Props) => {
  let [user, setUser] = useState(null);
  const firstParam = useGetFirstUrlParam();
  const history = useHistory();

  const Login = useContext(UserContext);



  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (Login.user === null) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          Login.user = user;
          setUser(user);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const handleLogout = async () => {
    try {
      Auth.signOut().then(() => { console.log("Sesion terminada") });
      Login.user = null;
      history.push(`/${firstParam}/`);
    } catch (error) {
      console.error("Ha ocurrido un error: ", error)
    }
  };

  return (
    <UserContext.Provider value={Login}>
      {Login.user ? <>
        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Typography style={{ cursor: "pointer" }} sx={{ minWidth: 100 }} onClick={() => { history.push(`/${firstParam}/`); }}>Home</Typography>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>

                  <div className="container-profile-picture-investigador">
                    <img
                      src={`/avatar/${(Login.user as any)?.challengeName==='NEW_PASSWORD_REQUIRED'?(Login.user as any)?.challengeParam.userAttributes["custom:id_investigador"]:(Login.user as any)?.attributes["custom:id_investigador"]}.jpg`}
                      alt="Profile card investigador"
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.style.maxWidth = "54px";
                        e.target.src =
                          "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fzancada.com%2Fwp-content%2Fimagenes%2F2012%2F12%2Ffacebook-profile-picture-n-copy.jpg&f=1&nofb=1";
                      }}
                    />
                  </div>

                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {/*
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />*/}
            <MenuItem style={{ cursor: "pointer" }} onClick={() => { history.push(`/${firstParam}/profile/reseacher/${(Login.user as any)?.challengeName==='NEW_PASSWORD_REQUIRED'?(Login.user as any)?.challengeParam.userAttributes["custom:universidad"]:(Login.user as any)?.attributes["custom:universidad"]}/${(Login.user as any)?.challengeName==='NEW_PASSWORD_REQUIRED'?(Login.user as any)?.challengeParam.userAttributes["custom:id_investigador"]:(Login.user as any)?.attributes["custom:id_investigador"]}`); }}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              Perfil
            </MenuItem>
            <MenuItem style={{ cursor: "pointer" }} onClick={() => { history.push(`/${firstParam}/cambiarpassword`); }}>
              <ListItemIcon>
                <Password fontSize="small" />
              </ListItemIcon>
              Cambiar contraseña
            </MenuItem>
            <MenuItem style={{ cursor: "pointer" }} onClick={handleLogout}>
              <ListItemIcon>
                <Link to={``} onClick={() => { handleLogout() }}>
                  <Logout fontSize="small" />
                </Link>
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      </>
        :
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Typography style={{ cursor: "pointer" }} sx={{ minWidth: 100 }} onClick={() => { history.push(`/${firstParam}/ingresar`); }}>Iniciar Sesi&oacute;n</Typography>
            <Typography style={{ cursor: "pointer" }} sx={{ minWidth: 100 }} onClick={() => { history.push(`/${firstParam}/registro`); }}>Registrarse</Typography>
          </Box>
        </>}
    </UserContext.Provider>

  );
}
