import TechnologyItem from "../components/TechnologyItem";
import { Technology } from "../../../interfaces/Technology";
import { useParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import React, { useEffect } from "react";
import { ITabs } from "Result/tabs/shared/interfaces/TabsProps";
import { WrapperResults } from "Result/wrapper-results/WrapperResults";

interface Props extends ITabs {
	data: Technology[];
}

const TechnologyContainer = ({ data, error, loading, query }: Props) => {
	const { university } = useParams<{ university: string }>();
	useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: "visita",
				pagePath: "tecnologias",
				university: university,
			},
		});
	}, [university]);

	const TechnologyResults = (): JSX.Element[] => {
		return data.map((technologyItem: Technology, index: number) => {
			return (
				<React.Fragment key={technologyItem._meta.id}>
					<TechnologyItem {...technologyItem} />
				</React.Fragment>
			);
		});
	};

	return (
		<>
			<WrapperResults
				component={TechnologyResults}
				data={data}
				error={error}
				query={query}
				loading={loading}
			/>
		</>
	);
};
export default TechnologyContainer;
