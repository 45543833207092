import scienteficPostsIcon from "../../../assets/icon_paper_pencil.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";
import useArrPaginator from "../../../hooks/useArrPaginator";
import "./styles/ScientificPosts.css";
import AbstractModal from "global/components/AbstractModal";
interface IPosts {
	dia: null | string;
	mes: string | null;
	resumen: string | null;
	lenguaje: string | null;
	categoria: string | null;
	subtitulo: string | null;
	publicado_en: string | null;
	colaboradores: string | null;
	id_publicacion: any;
	link_publicacion: string | any;
	tipo_publicacion: string | null;
	titulo_publicacion: string;
	lugar_anio_publicacion: string | null;
}
interface Props {
	posts: IPosts[];
}

export const ScientificPosts = ({ posts }: Props) => {
	const { itemsToShow: postsToShow, handleClick } = useArrPaginator<IPosts>(posts);
	return (
		<DropDownWithIcon
			title="Publicaciones científicas"
			icon={() => <img src={scienteficPostsIcon} alt="Icon Publicaciones científicas" />}
		>
			{postsToShow.map((item, index) => (
				<div
					key={item.titulo_publicacion + index}
					className="dropdown-information-perfil-investigador 
          dropdown-information-perfil-investigador information-grid"
				>
					{!item.link_publicacion ? (
						<p className="text-blue-publicaciones-cientificas">{item.titulo_publicacion}</p>
					) : (
						<p className="text-blue-publicaciones-cientificas">
							<a
								className="text-blue-publicaciones-cientificas"
								href={item.link_publicacion}
								target="_blank"
								rel="noopener noreferrer"
							>
								{item.titulo_publicacion}
							</a>
						</p>
					)}

					{item.colaboradores && item.colaboradores !== "" && (
						<p className="current-text-publicaciones-cientificas">
							<strong>Autor(es): </strong>
							{item.colaboradores}
						</p>
					)}
					{item.lugar_anio_publicacion && item.lugar_anio_publicacion !== "" && (
						<p className="current-text-publicaciones-cientificas">
							<strong>Publicado en: </strong>
							{item.lugar_anio_publicacion}
						</p>
					)}
					{item.publicado_en && item.publicado_en !== "" && (
						<p className="current-text-publicaciones-cientificas">
							<strong>Revista: </strong>
							{item.publicado_en}
						</p>
					)}

					{item.resumen && (
						<AbstractModal title={item.titulo_publicacion} resume={item.resumen} buttonName="Leer resumen" />
					)}

					{index === postsToShow.length - 1 && postsToShow.length !== posts.length && (
						<button className="link-mas-publicaciones-cientificas" onClick={() => handleClick()}>
							Ver más publicaciones
						</button>
					)}
				</div>
			))}
		</DropDownWithIcon>
	);
};
