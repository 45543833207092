
import { useHistory} from "react-router-dom";
import "./styles/BackButton.css";

export const BackButton = () => {
  const history = useHistory();

  return (
    <>
    
    
      <div className="header-flex">

        <div className='logo-header'>
          <button onClick={() => {history.goBack()}} className="header-volver">
            <i className="gg-chevron-left"></i>Volver
          </button>

        </div>
      </div>
    </>
  );
};
