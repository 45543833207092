import { Link } from "react-router-dom";

import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import { useParams } from "react-router-dom";

import "./styles/ResearcherItem.css";

import { IResearcherLS as Props } from "Result/tabs/researcher/interfaces/IResearcher";
import { Fragment, useContext, useEffect, useState } from "react";
import { Context } from "global/reduxState/store";
import { axiosIaInstance } from "global/utils/axios";
import { Box, CircularProgress } from "@mui/material";
import { WhyResponse } from "Result/interfaces/WhyResponse";

const ResearcherItem = ({
	nombres,
	universidad,
	centro,
	area_de_expertise,
	resumen,
	facultad,
	withIa,
	nota,
	razon,
	_meta,
}: Props) => {
	const fullNameUniversity = translateUniversityAcronym(universidad?.raw);
	// const currentLocation = getUrlFirstParam(location.pathname);
	const { university = "" } = useParams<{ university: string }>();

	return (
		<>
			<div className="profile-investigador">
				<img
					src={_meta.id ? `/avatar/${_meta.id}.jpg` : ""}
					className="profile-picture-investigador"
					onError={(e: any) => {
						e.target.onerror = null;
						e.target.src =
							"https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fzancada.com%2Fwp-content%2Fimagenes%2F2012%2F12%2Ffacebook-profile-picture-n-copy.jpg&f=1&nofb=1";
					}}
					alt="Researcher"
				/>
				{/* CARD */}
				<div className="profile-information-investigador">
					{nombres?.raw && (
						<Link
							className="profile-name-investigador profile-name-investigador-item"
							to={`/${university}/profile/reseacher/${universidad?.raw}/${_meta.id as unknown as number}`}
						>
							{nombres.raw}
						</Link>
					)}

					{fullNameUniversity && (
						<p className="profile-item-investigador">
							Universidad: <strong>{fullNameUniversity}</strong>
						</p>
					)}

					{centro?.raw && (
						<p className="profile-item-investigador">
							Centro: <strong>{centro.raw}</strong>
						</p>
					)}
					{facultad?.raw && (
						<p className="profile-item-investigador">
							Facultad: <strong>{facultad.raw}</strong>
						</p>
					)}
					{
						area_de_expertise?.raw && area_de_expertise.raw.length > 0 && (
							<p className="profile-item-investigador">
								Área de expertise:
								<strong>
									{area_de_expertise?.raw.map((experticeJSON, index) => {
										const expertice = JSON.parse(experticeJSON);

										return index === area_de_expertise.raw.length - 1
											? ` ${expertice.area}`
											: ` ${expertice.area},`;
									})}
								</strong>
							</p>
						)
						/* <p className="profile-item-investigador">
            Área de expertise: <strong>{area_de_expertise.raw} </strong>{" "}
          </p> */
					}

					{resumen?.raw && <p className="profile-resume-investigador"> {resumen.raw} </p>}
					{
						withIa && 
						<Fragment>
							<p className="profile-resume-investigador">Nivel de relación:{nota}</p>
							<p className="profile-resume-investigador">Justificación: {razon}</p>
						</Fragment>
					}
				</div>
				{/* ENDCARD */}
			</div>
			<hr />
		</>
	);
};

export default ResearcherItem;
