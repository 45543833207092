import TagManager from "react-gtm-module";
import { Link, useParams } from "react-router-dom";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import "./styles/Register.css";
import React, { useEffect, useState } from 'react';
import { axiosIaInstance, axiosInstance } from "global/utils/axios";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Slide, Stack } from "@mui/material";
import { IconVisibility, IconVisibilityOff } from "@aws-amplify/ui-react";
import { validarEmail, validarRegistro, validarRut } from "global/utils/validators";
import { createTheme } from "@mui/material/styles";
import { blue, orange } from '@mui/material/colors';
import { TransitionProps } from '@mui/material/transitions';
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
	palette: {
		primary: orange,
		secondary: blue
	}
});

interface University {
	id: number;
	name: string;
	acronym: string
}

export const Register = () => {
	const { university = "" } = useParams<{ university: string }>();
	TagManager.dataLayer({
		dataLayer: {
			event: "visita",
			pagePath: "Register",
			university: translateUniversityAcronym(university) || university,
		},
	});
	const [firstTry, setFirstTry] = useState(false);
	const [firstTryRut, setFirstTryRut] = useState(false);
	const [email, setEmail] = useState('');
	const [universidadSeleccionada, setUniversdadSeleccionada] = useState('');
	const [universidadDisabled, setUniversidadDisabled] = useState(false);
	const [personOptions, setPersonOptions] = useState([]);
	const [selectedUser, setSelectedUser] = useState<any | null>(null);
	const [inputSelectedUser, setInputSelectedUser] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const handleUniversidad = (e: SelectChangeEvent) => setUniversdadSeleccionada(e.target.value);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
	const handleMouseDownPasswordConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [userType, setUserType] = useState('Investigador');
	const [rut, setRut] = useState('');
	const [showErrors, setShowErrors] = useState<any>({ state: true, errors: [] })

	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false);
	const [noApareces, setNoApareces] = useState(false);
	const [polityCheck, setPolityCheck] = useState(false);
	const [polityCheckDisabled, setPolityCheckDisabled] = useState(true);
	const [listaUniversidades, setListaUniversidades] = useState<University[]>([]);

	const handleCloseModal = () => {
		setOpen(false);
	};
	const openNoApareces = () => {
		setNoApareces(true);
	};
	const handleCloseModalNoAparece = () => {
		setNoApareces(false);
	};

	const handlePolityCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPolityCheck(event.target.checked);
	}
	const handleInputSelectedUser = (input: string) => {
		setInputSelectedUser(input);
	}
	const handleSelectedUser = (input: any | null) => {
		setSelectedUser(input);
		setNoApareces(false);
		setUniversdadSeleccionada(input.university);
		setUniversidadDisabled(true);
	}
	const handleSelectedUserBlur = () => {
		console.log("[" + selectedUser?.label + "][" + inputSelectedUser + "]");
		if (selectedUser?.label === inputSelectedUser) {
			setUniversdadSeleccionada(selectedUser.university);
			setUniversidadDisabled(true);
		} else {
			setSelectedUser(null);
			setUniversidadDisabled(false);
		}
	}

	const handleChangeUserType = (event: SelectChangeEvent) => {
		setUserType(event.target.value as string);
	};
	const handleRutChange = (rut: string) => {
		let rutTmp = rut;
		if (validarRut(rut)) {
			rutTmp = rutTmp.replace(/\./g, '').replace(/\-/g, '');

			// Separar el número y el dígito verificador
			const rutNumero = parseInt(rutTmp.slice(0, -1), 10);
			const rutDV = rutTmp.slice(-1).toUpperCase();
			rutTmp = rutNumero + '-' + rutDV;
		}
		setRut(rutTmp);
	};



	useEffect(() => {
		const fetchPersonNames = async () => {
			try {
				const response = await axiosInstance.get('/allreseachernames');
				const data: any = await response.data.response;
				const options = data.map((person: any) => ({
					value: person["id"],
					label: person["label"],
					university: person["universidad"]
				}));
				setPersonOptions(options);
			} catch (error) {
				console.error('Error fetching person names:', error);
			}
		};
		const fetchUniversities = async () => {
			try {
				const resp = await axiosInstance.get('/utilitarias/listuniversities');
				setListaUniversidades(resp.data.listUniversitiesResponse.universities);
				setUniversdadSeleccionada(resp.data.listUniversitiesResponse.universities[0].acronym);
				return resp.data.listUniversitiesResponse.universities;
			} catch (error) {
				console.error('Error fetching listuniversities:', error);
			}
		};

		fetchPersonNames();
		fetchUniversities();
	}, []);

	const getBodyRequest = (email: string, id: string, nombres: string, password: string, userType: string, universidad: string, rut: string) => {
		let groups: any = []
		if (userType == "Invesigador-Director") {
			groups.push("Invesigador-Director")
			groups.push("Invesigador")
		} else {
			groups.push(userType)
		}
		return {
			"email": email,
			"nombres": nombres,
			"password": password,
			"universidad": universidad,
			"rut": rut,
			"groups": groups,
			"id": id
		}
	}

	const firstParam = useGetFirstUrlParam();

	const handleRegistration = async () => {
		try {
			setLoading(true)
			setShowErrors(validarRegistro({ email, nombres: inputSelectedUser, password, confirmPassword, userType, universidadSeleccionada, rut }))
			const body = getBodyRequest(email, selectedUser.value, selectedUser.label, password, userType, universidadSeleccionada, rut)
			const resp = await axiosInstance.post<any>(`users/register`, body)
			setLoading(false)
			console.log(showErrors)
			if (resp.data.code === 'OK') {
				setOpen(true);
			} else {
				setShowErrors({
					state: false,
					errors: [{ code: 1, message: resp.data.message }]
				})
			}
		} catch (error) {
			console.error("Ha ocurrido un error: ", JSON.stringify(error))
		}
	};
	return (
		<div style={{ backgroundImage: `url("/images/caps/Cubo_hubtec.svg")`, backgroundRepeat: "no-repeat", width: "80%", margin: "0px auto", maxWidth: "900px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", }}>
			<Box
				className="home-component"
				sx={{ width: 400, marginTop: 3, marginBottom: 5, backgroundColor: "transparent" }}
			>
				<p>CaPS es un programa de transformaci&oacute;n de capacidades de I+D en productos y servicios que respondan a necesidades de la industria.</p>
			</Box>
			<Box
				className="home-component"
				sx={{ width: 400, marginTop: 3, marginBottom: 5, backgroundColor: "white" }}
			>
				<div className="registration-container">
					<h2>Registro de usuario</h2>
					{/*}
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
						<InputLabel id="demo-simple-select-label">Tipo de usuario</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={userType}
							label="Tipo de usuario"
							onChange={handleChangeUserType}
						>
						
							<MenuItem value={"Investigador"}>Investigador</MenuItem>
							<MenuItem value={"Investigador-Director"}>Director</MenuItem>
							<MenuItem value={"Empresa"}>Empresa</MenuItem>
						</Select>
					</FormControl>
	{*/}
					<Autocomplete
						fullWidth
						value={selectedUser}
						onChange={(event: any, newValue: string | null) => {
							handleSelectedUser(newValue);
						}}
						inputValue={inputSelectedUser}
						onInputChange={(event, newInputValue) => {
							handleInputSelectedUser(newInputValue);
						}}
						id="controllable-states-demo"
						options={personOptions}
						freeSolo={true}
						onBlur={handleSelectedUserBlur}
						onFocus={() => { setSelectedUser(null) }}
						renderInput={(params) => <TextField {...params} label="Nombre completo" />}
					/>
					{selectedUser ?
						<span className="contacto-form-policies">
							<Link to={{ pathname: `/${firstParam}/profile/reseacher/${selectedUser.university}/${selectedUser.value}` }} target='_blank'>Revisa tu perfil actual aqu&iacute;</Link>
						</span>
						: inputSelectedUser === '' ? '' :
							<span className="contacto-form-policies">
								<Link to='#' onClick={openNoApareces} >¿No apareces en el listado?</Link>
							</span>
					}
					<TextField error={firstTryRut && !validarRut(rut)} onBlur={() => setFirstTryRut(true)} fullWidth sx={{ mt: 1, mb: 1 }} id="outlined-basic" label="Rut" type="text" value={rut} onChange={(e) => handleRutChange(e.target.value)} />
					<TextField error={firstTry && !validarEmail(email)} onBlur={() => setFirstTry(true)} fullWidth sx={{ mt: 1, mb: 1 }} id="outlined-basic" label="Email corporativo" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
						<InputLabel id="demo-simple-select-label">Universidad</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={universidadSeleccionada}
							label="Universidad"
							onChange={handleUniversidad}
							disabled={universidadDisabled}
						>
							{listaUniversidades.map((u) => {
								return (<MenuItem value={u.acronym}>{u.name}</MenuItem>);
							})}
						</Select>
					</FormControl>

					{polityCheckDisabled ?
						<Checkbox
							//className="contact-form-checkbox-form-input"
							checked={polityCheck}
							disabled={polityCheckDisabled}
							style={{ color: "#d0cece" }}
							sx={{
								color: "#d0cece",
							  }}

					/>:
					<Checkbox
						className="contact-form-checkbox-form-input"
						checked={polityCheck}
						onChange={handlePolityCheck}
						disabled={polityCheckDisabled}
						
						
					/>

					}



					<span className="contacto-form-policies">
						He leído y acepto las <Link to={{ pathname: `/${firstParam}/privacy` }} target='_blank' onClick={() => { setPolityCheckDisabled(false); }}>políticas de privacidad </Link>
					</span>
					{
						!showErrors.state ?
							<>
								{showErrors.errors.map((error: any) => {
									return <p style={{ color: "red" }}>*{error.message}</p>
								})}
							</> :
							<></>
					}
					<Stack spacing={1} direction="row" justifyContent="center" marginTop={2}>
						<Button disabled={!polityCheck || selectedUser === null} variant="contained" onClick={handleRegistration} style={{ backgroundColor: "#FE894A" }}>Registrarse</Button>
					</Stack>
				</div>

				<Dialog
					open={open && showErrors.state}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleCloseModal}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle>{"Confirmación de email"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Para poder ingresar debes usar tu correo electr&oacute;nico y la contraseña temporal que se ha enviado la misma cuenta de correo electr&oacute;nico.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Link to={`/${firstParam}/`} onClick={handleCloseModal}>
							<Button>Ir a la pagina principal</Button>
						</Link>
						<Link to={`/${firstParam}/ingresar`} onClick={handleCloseModal}>
							<Button>Ingresar a la plataforma</Button>
						</Link>
					</DialogActions>

				</Dialog>

				<Dialog
					open={noApareces}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleCloseModalNoAparece}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle>{"Solicitud de registro"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Lo sentimos! Actualmente no te encuentras en nuestros registros. Favor inscríbete en el siguiente <a href="https://forms.gle/WwUui48dfDrprypZ7" target="_blank" rel="noopener noreferrer">
								formulario
							</a>.
						</DialogContentText>

						<DialogContentText id="alert-dialog-slide-description">
							Te comunicaremos una vez que el perfil haya sido ingresado a CaPS.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Link to={`/${firstParam}/`} onClick={handleCloseModalNoAparece}>
							<Button>Aceptar</Button>
						</Link>
					</DialogActions>
				</Dialog>
			</Box>

		</div>
	);
};