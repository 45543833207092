import "./styles/Overview.css";
import overviewIcon from "../../../assets/icon_overview.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";

interface Study {
	pais: string | null;
	escuela: string | null;
	estudio: string | null;
	institucion: string | null;
}
interface Props {
	studies: {
		preGrado: Study[];
		postGrado: Study[];
		estudios: Study[];
	};
}

const DropdownIcon = () => <img src={overviewIcon} alt="Icon Propiedad intelectual" />;

export const Overview = ({ studies }: Props): JSX.Element => {
	return (
		<DropDownWithIcon title={"OVERVIEW"} icon={DropdownIcon}>
			<>
				{!!studies && (
					<div className="dropdown-information-perfil-investigador dropdown-information-perfil-investigador information-grid information-grid-overview">
						{studies.preGrado.length > 0 && (
							<>
								<p className="title-overview">Estudios de Pregrado</p>
								{studies.preGrado.map(({ estudio, institucion, pais }: any, index: number) => (
									<span key={estudio + index}>
										<p className="text-dropdown-generic"><strong>• {estudio}</strong></p>
										{institucion && institucion !== "" && (
											<p className="text-dropdown-generic">{institucion}</p>
										)}
										{pais && pais !== "" && <p className="text-dropdown-generic">{pais}</p>}
									</span>
								))}
								{ studies.preGrado?.length > 0 || studies.estudios?.length > 0 ? <br></br> : null}
								
							</>
						)}
						{studies.postGrado.length > 0 && (
							<>
								<p className="title-overview">Estudios de Postgrado</p>
								{studies.postGrado.map(({ estudio, institucion, pais }: any, index: number) => (
									<span key={estudio + index}>
										<p className="text-dropdown-generic"><strong>• {estudio}</strong></p>
										{institucion && institucion !== "" && (
											<p className="text-dropdown-generic">{institucion}</p>
										)}
										{pais && pais !== "" && <p className="text-dropdown-generic">{pais}</p>}
									</span>
								))}
								{ studies.estudios?.length > 0 ? <br></br> : null}
							</>
						)}
						{studies.estudios.length > 0 && (
							<>
								<p className="title-overview">Estudios</p>
								{studies.estudios.map(({ estudio, institucion, pais }: any, index: number) => (
									<span key={estudio + index}>
										<p className="text-dropdown-generic"><strong>• {estudio}</strong></p>
										{institucion && institucion !== "" && (
											<p className="text-dropdown-generic">{institucion}</p>
										)}
										{pais && pais !== "" && <p className="text-dropdown-generic">{pais}</p>}
									</span>
								))}
							</>
						)}
					</div>
				)}
			</>
		</DropDownWithIcon>
	);
};
