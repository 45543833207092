import TagManager from "react-gtm-module";
import { Link, useParams, useLocation } from "react-router-dom";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import "./styles/RecoverPass.css";
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Slide, Stack } from "@mui/material";
import { IconVisibility, IconVisibilityOff } from "@aws-amplify/ui-react";
import { validarEmail } from "global/utils/validators";
import { Amplify, Auth } from 'aws-amplify';
import queryString from "query-string";
import { validarCambioPassword } from "global/utils/validators";
import { TransitionProps } from '@mui/material/transitions';
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const RecoverPassStep2 = () => {
	const { university = "" } = useParams<{ university: string }>();

	const [codigo, setCodigo] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
	const handleMouseDownPasswordConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const [firstTry, setFirstTry] = useState(false);
	const [loginError, setLoginError] = useState('');
	const location = useLocation();
	const { user }: any = queryString.parse(location.search);
	const [showErrors, setShowErrors] = useState<any>({ state: true, errors: [] });
	const [open, setOpen] = useState(false);
	const firstParam = useGetFirstUrlParam();

	const handleCodigoChange = (codigo: string) => {

		setCodigo(codigo);
	};
	const handleCloseModal = () => {
		setOpen(false);
	};

	const handlePasswordRecovery = async () => {
		try {
			const passwordOld = "";
			setShowErrors(validarCambioPassword({ passwordOld, password, confirmPassword }));
			if (!showErrors.state) {
				setLoginError('');
				return;
			}
			const data = await Auth.forgotPasswordSubmit(user, codigo, password);
			setLoginError('');
			setOpen(true);
		} catch (error) {
			setLoginError(processForgotPasswordError(error));
		}

	};
	TagManager.dataLayer({
		dataLayer: {
			event: "visita",
			pagePath: "RecoverPass",
			university: translateUniversityAcronym(university) || university,
		},
	});
	const processForgotPasswordError = (error: any): string => {
		switch (error.code) {
			case 'CodeMismatchException':
				return 'El código de confirmación proporcionado no es válido.';
			case 'ExpiredCodeException':
				return 'El código de confirmación ha expirado. Por favor, solicita uno nuevo.';
			case 'InvalidPasswordException':
				return 'La nueva contraseña proporcionada no cumple con la política de contraseñas.';
			default:
				return 'Ha ocurrido un error inesperado. Por favor, intenta nuevamente.';
		}
	};

	return (
		<Box
			className="home-component"
			sx={{ width: 400, marginTop: 3, marginBottom: 5 }}
		>
			<div className="password-recovery-container">
				<h2>Recuperar contraseña</h2>
				Ingresa el c&oacute;digo que fue enviado a tu correo electr&oacute;nico y luego ingresa tu nueva contraseña.
				<TextField fullWidth sx={{ mt: 1, mb: 1 }} id="outlined-basic" label="Código de Recuperación" type="text" value={codigo} onChange={(e) => handleCodigoChange(e.target.value)} />
				<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
					<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined css-1jy569b-MuiFormLabel-root-MuiInputLabel-root" >
						La contraseña nueva debe contener números, caracteres
					</label>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
					<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined css-1jy569b-MuiFormLabel-root-MuiInputLabel-root" >
						especiales, mayúsculas y minúsculas</label>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
					<InputLabel htmlFor="outlined-adornment-password">Contraseña nueva</InputLabel>
					<OutlinedInput
						id="outlined-adornment-password"
						type={showPassword ? 'text' : 'password'}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? <IconVisibilityOff /> : <IconVisibility />}
								</IconButton>
							</InputAdornment>
						}
						label="Contraseña nueva"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
					<InputLabel htmlFor="outlined-adornment-password">Confirmar contraseña nueva</InputLabel>
					<OutlinedInput
						id="outlined-adornment-password"
						type={showPasswordConfirm ? 'text' : 'password'}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPasswordConfirm}
									onMouseDown={handleMouseDownPasswordConfirm}
									edge="end"
								>
									{showPasswordConfirm ? <IconVisibilityOff /> : <IconVisibility />}
								</IconButton>
							</InputAdornment>
						}
						label="Confirmar contraseña nueva"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					/>
				</FormControl>
				{
					!showErrors.state ?
						<>
							{showErrors.errors.map((error: any) => {
								return <p style={{ color: "red" }}>*{error.message}</p>
							})}
						</> :
						<></>
				}
				{
					loginError === '' ?
						<></> :
						<p style={{ color: "red" }}>*{loginError}</p>
				}
				<Stack spacing={1} direction="row" justifyContent="center" marginTop={2}>
					<Button variant="contained" onClick={handlePasswordRecovery} style={{ backgroundColor: "#FE894A" }}>Recuperar contraseña</Button>
				</Stack>
			</div>

			<Dialog
					open={open && showErrors.state}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleCloseModal}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle>{"Confirmación de restablecimineto de contraseña"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Se ha restablecido tu contraseña.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Link to={`/${firstParam}/ingresar`} onClick={handleCloseModal}>
							<Button>Aceptar</Button>
						</Link>
					</DialogActions>
					
				</Dialog>
		</Box>
	);
};
