interface UniversityInformation {
	[universityAcronym: string]: {
		link: string;
		emails: string[];
		cellPhoneNumbers: string[];
	};
}
const universitiesInformation: UniversityInformation = {
	udd: {
		link: "https://www.udd.cl/investigacion/",
		emails: ["icono@udd.cl"],
		cellPhoneNumbers: ["+56 225783443"],
	},
	caps: {
		link: "https://www.hubtec.cl/",
		emails: ["soportecaps@hubtec.cl", "contacto@hubtec.cl"],
		cellPhoneNumbers: ["+56 9 3309 8864"],
	},
	ufro: {
		link: "https://innovacion.ufro.cl/transferencia-tecnologica/",
		emails: ["utt@ufrontera.cl"],
		cellPhoneNumbers: ["+56 452734138"],
	},
};

export default universitiesInformation;
