import serviceItemStyles from "./styles/ServiceItem.module.css";
import { ElasticFormatObligatory, ElasticFormatNotObligatory } from "global/interfaces/Elastic";
import { Link, useParams } from "react-router-dom";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import ContactForm from "global/components/ContactForm";
import { Fragment, useContext } from "react";
import { UserContext } from "auth/login/container/UserContext";
interface Props {
	id_centro: ElasticFormatNotObligatory;
	nombre: ElasticFormatObligatory;
	facultad: ElasticFormatNotObligatory;
	universidad: ElasticFormatNotObligatory;
	descripcion: ElasticFormatNotObligatory;
	laboratorio: ElasticFormatNotObligatory;
	centro: ElasticFormatNotObligatory;
	centro_existe: ElasticFormatNotObligatory;
	withIa: Boolean;
	nota?: string;
	razon?: string;
	id: ElasticFormatObligatory;
}

const ServiceItem = ({
	nombre,
	id_centro,
	facultad,
	universidad,
	descripcion,
	laboratorio,
	centro,
	centro_existe,
	id,
	withIa,
	nota,
	razon
}: Props) => {
	const { university: currentLocation } = useParams<any>();

	const serviceNameAndId = nombre.raw + " / " + id.raw;

	const fullNameUniversity = translateUniversityAcronym(universidad.raw);

	const Login = useContext(UserContext);

	return (
		<div className={serviceItemStyles["container-servicio"]}>
			<div className={serviceItemStyles["information-centro"]}>
				<p className={serviceItemStyles["nombre_servicio"]}>{nombre?.raw}</p>
				<hr />
				{centro?.raw && (
					<div className={serviceItemStyles["item_servicio"]}>
						<p className={`${serviceItemStyles["name_item_servicio"]} ${serviceItemStyles["bold"]}`}>
							Centro:{" "}
						</p>{" "}
						{id_centro && centro_existe?.raw === "1" ? (
							<Link
								className={serviceItemStyles["link_item_servicio"]}
								to={`/${currentLocation}/profile/hub/${universidad?.raw}/${id_centro?.raw}`}
							>
								{centro.raw}
							</Link>
						) : (
							<p className={serviceItemStyles["link_item_servicio-no-link"]} style={{ display: "inline" }}>
								{" "}
								{centro.raw}
							</p>
						)}
					</div>
				)}
				{facultad?.raw && (
					<div className={serviceItemStyles["item_servicio"]}>
						<p className={`${serviceItemStyles["name_item_servicio"]} ${serviceItemStyles["bold"]}`}>
							Facultad:{" "}
						</p>{" "}
						<p className={serviceItemStyles["name_item_servicio"]}> {facultad.raw}</p>
					</div>
				)}
				{universidad?.raw && (
					<div className={serviceItemStyles["item_servicio"]}>
						<p className={`${serviceItemStyles["name_item_servicio"]} ${serviceItemStyles["bold"]}`}>
							Universidad:{" "}
						</p>{" "}
						<p className={serviceItemStyles["name_item_servicio"]}>{fullNameUniversity || universidad.raw}</p>
					</div>
				)}
				{laboratorio?.raw && (
					<div className={serviceItemStyles["item_servicio"]}>
						<p className={`${serviceItemStyles["name_item_servicio"]} ${serviceItemStyles["bold"]}`}>
							Laboratorio:{" "}
						</p>{" "}
						<p className={serviceItemStyles["name_item_servicio"]}>{laboratorio.raw}</p>
					</div>
				)}
				{descripcion?.raw && (
					<div className={serviceItemStyles["texto-centrado"]}>
						<p className={`${serviceItemStyles["name_item_servicio"]} ${serviceItemStyles["bold"]}`}>
							Descripción:{" "}
						</p>{" "}
						<p className={serviceItemStyles["name_item_servicio"]} style={{ display: "inline" }}>
							{descripcion.raw}
						</p>
					</div>
				)}
				{Login.user &&
					<ContactForm
						currentLocation={currentLocation}
						origin={currentLocation}
						dimension="Servicios"
						location={serviceNameAndId}
						openModalElementText={"Contacto"}
						openModalElementClassName={serviceItemStyles["contacto_link_item_servicio"]}
						openModalElementAsButton={true}
					/>
				}
				{
					withIa &&
					<Fragment>
						<p className="profile-resume-investigador">Nivel de relación:{nota}</p>
						<p className="profile-resume-investigador">Justificación: {razon}</p>
					</Fragment>
				}
			</div>
		</div>
	);
};

export default ServiceItem;
