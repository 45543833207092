import TagManager from "react-gtm-module";
import { Link, useHistory, useParams } from "react-router-dom";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import "./styles/Login.css";
import { useState, useContext } from 'react';
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from "@mui/material";
import { validarEmail } from "global/utils/validators";
import { IconVisibility, IconVisibilityOff } from "@aws-amplify/ui-react";
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";
import { UserContext } from './UserContext';


import { Amplify, API, Auth } from 'aws-amplify';
import awsconfig from '../../../aws-config';

Amplify.configure(awsconfig);


export const Login = () => {
	const { university = "" } = useParams<{ university: string }>();
	//Marca visita de google analytics
	TagManager.dataLayer({
		dataLayer: {
			event: "visita",
			pagePath: "Login",
			university: translateUniversityAcronym(university) || university,
		},
	});

	const Login = useContext(UserContext);

	const history = useHistory();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loginError, setLoginError] = useState('');
	const [firstTry, setFirstTry] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const [loading, setLoading] = useState(false)

	const firstParam = useGetFirstUrlParam();

	const handleLogin = async () => {
		setLoading(true)
		try {

			const user = await Auth.signIn(email, password);
			//se setea usuario en el contexto
			Login.user = user;//await Auth.currentAuthenticatedUser();;
			if (user?.challengeName==='NEW_PASSWORD_REQUIRED') {
				history.push(`/${firstParam}/cambiarpassword`);
			} else {
				history.push(`/${firstParam}/`);
			}
			setLoginError('');
		} catch (error) {
			//invalida el usuario del contexto
			Login.user = null;
			console.error("Ha ocurrido un error: ", JSON.stringify(error));
			setLoginError(processCognitoError(error));
		}
		setLoading(false)
	};

	const processCognitoError = (error: any): string => {
		switch (error?.code) {
		  case 'NotAuthorizedException':
			return 'Credenciales inválidas. Por favor, verifica tu correo electrónico y contraseña.';
		  case 'UserNotFoundException':
			return 'No se encontró un usuario con esa dirección de correo electrónico.';
		  case 'PasswordResetRequiredException':
			return 'Debes restablecer tu contraseña antes de iniciar sesión.';
		  case 'UserNotConfirmedException':
			return 'Debes confirmar tu registro antes de poder iniciar sesión. Revisa tu correo electrónico para más detalles.';
		  case 'InvalidParameterException':
			return 'Ha ocurrido un error en los parámetros proporcionados.';
		  case 'CodeDeliveryFailureException':
			return 'Ha habido un problema al enviar el código de confirmación. Por favor, intenta nuevamente más tarde.';
		  case 'TooManyRequestsException':
			return 'Has excedido el límite de intentos. Por favor, espera un momento antes de intentar nuevamente.';
		  case 'ExpiredCodeException':
			return 'El código de confirmación ha expirado. Por favor, solicita uno nuevo.';
		  default:
			return 'Ha ocurrido un error inesperado. Por favor, intenta nuevamente.';
		}
	  };

	return (
		<>
			<Box
				className="home-component"
				sx={{ width: 400, marginTop: 3, marginBottom: 5 }}
			>
				<div className="login-container">
					<h2>Iniciar sesión</h2>
					<TextField error={firstTry && !validarEmail(email)} onBlur={() => setFirstTry(true)} fullWidth sx={{ mt: 1, mb: 1 }} id="outlined-basic" label="Correo electrónico" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }} variant="outlined">
						<InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <IconVisibilityOff /> : <IconVisibility />}
									</IconButton>
								</InputAdornment>
							}
							label="Contraseña"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</FormControl>
					{
						loginError==='' ?
							<></>:
							<p style={{ color: "red" }}>*{loginError}</p> 							
					}
					
					<Stack spacing={1} direction="row" justifyContent="center" marginTop={2}>
						<Button variant="contained" onClick={handleLogin} style={{ backgroundColor: "#FE894A" }}>Iniciar sesión</Button>
					</Stack>
					<br></br>
					<FormControl>
					¿No tienes usuario? <Link to={`/${firstParam}/registro`}>Reg&iacute;strate aqu&iacute;</Link>
					</FormControl>
					<FormControl>
					¿No recuerdas tu contraseña? <Link to={`/${firstParam}/recuperar`}>Recup&eacute;rala aqu&iacute;
						</Link>
					</FormControl>
					
				</div>
			</Box>
		</>
	);
};
