import ServiceItem from "../components/ServiceItem";
import { Service } from "../../../interfaces/Service";
import TagManager from "react-gtm-module";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { ITabs } from "Result/tabs/shared/interfaces/TabsProps";
import { WrapperResults } from "Result/wrapper-results/WrapperResults";

interface Props extends ITabs {
	data: Service[];
}

const ServiceContainer = ({ data, error, loading, query }: Props) => {
	const { university } = useParams<{ university: string }>();
	useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: "visita",
				pagePath: "servicios",
				university: university,
			},
		});
	}, [university]);

	const serviceResults = (): JSX.Element[] => {
		return data.map((serviceItem: Service, index: number) => {
			return (
				<React.Fragment key={serviceItem._meta.id}>
					<ServiceItem {...serviceItem} />
				</React.Fragment>
			);
		});
	};

	return (
		<WrapperResults component={serviceResults} data={data} error={error} query={query} loading={loading} />
	);
};
export default ServiceContainer;
