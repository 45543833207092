import { Box, CircularProgress } from "@mui/material";
import NoResults from "global/components/NoResults";
import { useContext } from "react";
import { Context } from "global/reduxState/store";

interface Props {
	loading: boolean;
	error: boolean;
	component: any;
	query: string;
	data: any;
}

export const WrapperResults = ({ loading, error, component: Component, data, query }: Props) => {
	const [{ typeSearch}, dispatch] = useContext(Context);
	if (loading) {
		return (
			<div
				style={{
					display: "flex",
					minHeight: "250px",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				{ typeSearch == "ia" ? 
					<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<CircularProgress size={17}/>
						<p className="profile-item-investigador" style={{ paddingLeft: "6px"}}>Cargando Niveles de relación y Justificaciones de la búsqueda...</p>
					</Box>
				:	
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<CircularProgress size={17}/>
					<p className="profile-item-investigador" style={{ paddingLeft: "6px"}}>Cargando...</p>
				</Box>
			}
			</div>
		);
	}
	if (!error && data?.length > 0) {
		return <Component data={data} />;
	}

	return <NoResults query={query} />;
};
