import "./styles/Overview.css";
import overviewIcon from "../../../assets/Grupo-59.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";

const DropdownIcon = () => <img src={overviewIcon} alt="Icon Overview" />;

interface OverviewData {
	descripcion: string | null;
	propuesta_valor: string | null;
	ventajas_competitivas: string | null;
	link_corfo: string | null;
	expertice: Expertice[] | null;
}

interface Expertice {
	expertice: string;
}

const Overview = ({
	descripcion,
	propuesta_valor,
	ventajas_competitivas,
	link_corfo,
	expertice,
}: OverviewData): JSX.Element => {
	return (
		<DropDownWithIcon title={"OVERVIEW"} icon={DropdownIcon}>
			<div className="dropdown" data-dropdown>
				<div className="dropdown-information-perfil-centro">
					{descripcion && descripcion !== "" && (
						<>
							<p className="title-overview">Descripción</p>
							<p className="text-dropdown-generic">{descripcion}</p>
						</>
					)}
					
					{propuesta_valor && propuesta_valor !== "" && (
						<>
							<p className="title-overview">Propuesta de Valor</p>
							<p className="text-dropdown-generic">{propuesta_valor}</p>
						</>
					)}
					{ventajas_competitivas && ventajas_competitivas !== "" && (
						<>
							<p className="title-overview">Ventajas Competitivas</p>
							<p className="text-dropdown-generic">{ventajas_competitivas}</p>
						</>
					)}

					{ expertice && expertice.length > 0 && 
						<>
						
						<p className="title-overview">Áreas de Expertise </p>	
						{
						

							
						expertice?.map(({ expertice }: Expertice, index: number) => { return (
							
							<p className="text-dropdown-generic" key={expertice + index}>
								• {expertice}
							</p>
						)})
						}
							
						</>
					} 	

					{link_corfo && (
						<a href={link_corfo} target="_blank" className="text-big-overview" rel="noreferrer">
							Centro que se encuentra habilitado para celebrar Contratos de I+D con contribuyentes que quieran
							acceder a los beneficios tributarios de la Ley I+D.
						</a>
					)}
				</div>
			</div>
		</DropDownWithIcon>
	);
};

export default Overview;
