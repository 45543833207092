import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./styles/AbstractModal.css";


const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "100%",
	maxWidth: 700,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

interface Props {
	title: string;
	resume: string | null;
	buttonName: string;
}

const AbstractModal = ({ title, resume, buttonName }: Props) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<div className="abstract-modal-container">
			<button className="button" onClick={handleOpen}>
				{buttonName}
			</button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2" className="title">
						{title}
					</Typography>
					{resume && (
						<Typography id="modal-modal-description" sx={{ mt: 2 }} variant="body1" className="modal-resume">
							{resume}
						</Typography>
					)}
				</Box>
			</Modal>
		</div>
	);
};

export default AbstractModal;
