import { Search } from "../components/Search";
// import { Slider } from '../components/Slider'
// import { useIntersection } from '../../hooks/useIntersection';
//import { useRef } from "react";
import TagManager from "react-gtm-module";
import { useParams } from "react-router-dom";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import "./styles/Home.css";

export const Home = () => {
	const { university = "" } = useParams<{ university: string }>();
	/* const refSlider: any = useRef(); */
	// const sliderActive = useIntersection( refSlider, '-200px' )
	//Marca visita de google analytics
	TagManager.dataLayer({
		dataLayer: {
			event: "visita",
			pagePath: "Home",
			university: translateUniversityAcronym(university) || university,
		},
	});
	return (
		<>
			<div className="home-component">
				<Search />
				{/*         <div ref={refSlider}>
          <Slider automaticMoveSlider={ sliderActive } />
        </div> */}
			</div>
		</>
	);
};
