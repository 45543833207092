const UniversityAcronyms: any = {
	UDD: "Universidad del Desarrollo",
	UNAB: "Universidad Andrés Bello",
	PUC: "Pontificia Universidad Católica de Chile",
	UAndes: "Universidad de los Andes",
	UFRO: "Universidad de La Frontera",
	UV: "Universidad de Valparaíso",
	PUCV: "Pontificia Universidad Católica de Valparaíso",
	UChile: "Universidad de Chile",
	UdeC: "Universidad de Concepción",
	UAI: "Universidad Adolfo Ibáñez",
	UTFSM: "Universidad Técnica Federico Santa María",
	UCM: "Universidad Católica del Maule",
	UCN: "Universidad Católica del Norte",
	USS: "Universidad San Sebastián",
	UMayor: "Universidad Mayor",
	UST: "Universidad Santo Tomás",
	UTalca: "Universidad de Talca",
	CienciaVida: "Fundación Ciencia & Vida",
};

/**
 * Retornar el significado de las siglas de las universidades
 * @param input la sigla de la universidad, ejemplo: UDD retorna Universidad del Desarrollo
 * @returns string el significado de la sigla
 * @returns null cuando la sigla no existe o no es válida
 */
const translateUniversityAcronym = (input: string | null | undefined): string | null => {
	if (input) {
		if (input === null) {
			return null;
		}
		if (UniversityAcronyms.hasOwnProperty(input)) {
			return UniversityAcronyms[input];
		}
	}
	return null;
};

export default translateUniversityAcronym;
