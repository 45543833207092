const NoResults = ({ query, loading = false }: any) => {
	return (
		<div
			style={{
				display: "flex",
				minHeight: "250px",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			{!loading ? (
				<>
					<div>
						No conseguimos resultados para la búsqueda <strong>{query}</strong>{" "}
					</div>
					<div>
						Sugerencias:
						<br />
						Revisa que todas las palabras estén escritas correctamente.
						<br />
						Intenta ingresar palabras clave diferentes.
						<br />
						Intenta ingresar palabras clave más generales.
					</div>
				</>
			) : (
				<span>Cargando ...</span>
			)}
		</div>
	);
};

export default NoResults;
