interface RegisterUserData {
    email: string;
    nombres: string;
    password: string;
    confirmPassword: string;
    userType: string;
    universidadSeleccionada: string, 
    rut: string,
}

export function validarEmail(email: string): boolean {
    // Expresión regular para validar el formato del correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Verificar si el correo electrónico cumple con el formato esperado
    if(email.trim()=="" || !email) return false
    return regex.test(email);
}

export function validarRegistro(userData: RegisterUserData){
    let errors = []
    if(!validarEmail(userData["email"])) errors.push({code: 1 , message:"Email no válido."})
    if(userData["nombres"]=="" || !userData["nombres"]) errors.push({code: 2 , message:"Debe escoger su nombre de investigador (si no aparece contactar con soporte)."})
    //if(userData["password"]!==userData["confirmPassword"]) errors.push({code: 3 , message:"Las contraseñas no coinciden."})
    //if(userData["password"].length<6) errors.push({code: 4 , message:"La contraseña debe tener mínimo 6 caracteres."})
    if(userData["userType"]=="" || !userData["userType"]) errors.push({code: 5 , message:"Debe escoger un tipo de usuario."})
    if(userData["universidadSeleccionada"]=="" || !userData["universidadSeleccionada"]) errors.push({code: 6 , message:"Debe escoger una universidad."})
    if(!validarRut(userData["rut"])) errors.push({code: 7 , message:"Rut no válido."})
    return {
        state: errors.length == 0 ? true : false,
        errors
    };
}
export function validarCambioPassword(userData: {"passwordOld":string,"password": string, "confirmPassword": string}){
    let errors = []
    if(userData["password"]!==userData["confirmPassword"]) errors.push({code: 3 , message:"Las contraseñas no coinciden."})
    if(userData["password"].length<6) errors.push({code: 4 , message:"La contraseña debe tener mínimo 6 caracteres."})
    return {
        state: errors.length == 0 ? true : false,
        errors
    };
}

export function validarRut(rut: string){
		if (typeof rut !== 'string') {
			return false;
		}

		// Eliminar puntos y guiones del RUT
		rut = rut.replace(/\./g, '').replace(/\-/g, '');

		// Separar el número y el dígito verificador
		const rutNumero = parseInt(rut.slice(0, -1), 10);
		const rutDV = rut.slice(-1).toUpperCase();

		// Calcular el dígito verificador esperado
		const dvEsperado = calcularDigitoVerificador(rutNumero);

		// Comparar dígito verificador calculado con el proporcionado
		return rutDV === dvEsperado;
	}

	const calcularDigitoVerificador = (rutNumero: number) => {
		let suma = 0;
		let factor = 2;

		while (rutNumero > 0) {
			const digito = rutNumero % 10;
			suma += digito * factor;
			rutNumero = Math.floor(rutNumero / 10);
			factor = factor === 7 ? 2 : factor + 1;
		}

		const modulo = suma % 11;
		const dv = 11 - modulo;

		if (dv === 10) {
			return 'K';
		} else if (dv === 11) {
			return '0';
		} else {
			return dv.toString();
		}
	}