export const setWithExpiry = (key: string, value: any, ttl: number) => {

	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
};

export const setWithoutExpiry = (key: string, value: any) => {


	const item = {
		value: value
	}
	localStorage.setItem(key, JSON.stringify(item))
};


export const getWithExpiry = (key: string) => {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}


export const getWithoutExpiry = (key: string) => {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)

	return item.value
}

export const areDifferents = (key1: string, key2: string) => {
	const itemStr1 = localStorage.getItem(key1)
	const itemStr2 = localStorage.getItem(key2)

	
	if (!itemStr1 || !itemStr2) {
		return false
	}
	const item1 = JSON.parse(itemStr1);
	const item2 = JSON.parse(itemStr2);

	if(JSON.stringify(item1.value) === JSON.stringify(item2.value)){
		return false;
	}else{

		return true;
	}

}

