import "./styles/CenterItem.css";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import { Link } from "react-router-dom";
import useGetFirstUrlParam from "../../../../hooks/useGetFirstUrlParam";
import { Centro as Props } from "Result/interfaces/Centro";
import { Fragment } from "react";

export const CenterItem = ({
	id_centro,
	nombre_centro,
	descripcion,
	facultad,
	universidad,
	expertise,
	withIa,
	nota,
	razon,
}: Props): JSX.Element => {
	const fullNameUniversity = translateUniversityAcronym(universidad.raw);
	const currentLocation = useGetFirstUrlParam();
	
	return (
		<>
			<div className="container-centro">
				<div className="information-centro">
					<Link
						className="link-centro"
						to={{
							pathname: `/${currentLocation}/profile/hub/${universidad?.raw}/${id_centro?.raw}`,
						}}
					>
						{nombre_centro?.raw}
					</Link>
					{descripcion?.raw && descripcion?.raw !== "" && (
						<p className="text-centro">
							<strong>Descripción: </strong>
							{descripcion.raw}
						</p>
					)}
					{expertise?.raw && expertise?.raw !== "" && (
						<p className="text-centro">
							<strong>Áreas de Expertise: </strong>
							{expertise.raw}
						</p>
					)}
					{facultad?.raw && facultad?.raw !== "" && (
						<p className="text-centro">
							<strong>Facultad: </strong>
							{facultad.raw}
						</p>
					)}
					{fullNameUniversity && fullNameUniversity !== "" && (
						<p className="text-centro">
							<strong>Universidad: </strong>
							{fullNameUniversity}
						</p>
					)}
					{
						withIa && 
						<Fragment>
							<p className="profile-resume-investigador">Nivel de relación:{nota}</p>
							<p className="profile-resume-investigador">Justificación: {razon}</p>
						</Fragment>
					}
				</div>
			</div>
		</>
	);
};
