export enum UniversityCases {
	udd = "udd",
	caps = "caps",
	unab = "unab",
	uandes = "uandes",
	ufro = "ufro",
	pucv = "pucv",
	puc = "puc",
	uv = "uv",
}
