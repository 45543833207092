import { Route, RouteChildrenProps, Switch, useParams } from "react-router-dom";
import Home from "../../home";
import { Footer } from "../../home/components/Footer";
import { HeaderWithRouter } from "../../home/components/Header";
import Result from "../../Result";
import Store from "global/reduxState/store";
import ResearcherProfile from "../../profiles/researcher/index";
import HubProfile from "../../profiles/hub";
import { useEffect } from "react";
import { UniversityCases } from "../interfaces/Universities";
import TechnologyProfile from "../../profiles/technology/index";
import { PrivacyPoliciesBox } from "home/components/PrivacyPoliciesBox";
import Login from "auth/login";
import Register from "auth/register";
import {RecoverPass, RecoverPassStep2} from "auth/recoverpass";
import Changepass from "auth/changepass";
//Codigo de ejemplo para usar aws aplify para el login
//import Public from "./components/Public";
//import Private from "./components/Private";

function App({ history, location, match }: RouteChildrenProps) {
	const { university = "" } = useParams<{ university: string }>();
	const path = match?.path;

	
	useEffect(() => {
		// check if the university is valid in the enum types
		if (Object.values(UniversityCases).includes(`${university}` as UniversityCases)) {
			// check if the url have the university inside /params/
			if (!location.pathname.includes(`/${university}/`)) {
				history.replace(`/${university}/`);
			}
		} else {
			//If we have a wrong parameter we just redirect the user to the default url
			history.replace(`/caps/`);
		}

		var head = document.head;
		const link = document.createElement("link");
		link.type = "text/css";
		link.rel = "stylesheet";
		link.href = `/css/${university}/overwride.css`;
		head.appendChild(link);
		// eslint-disable-next-line
	}, [university]);
	return (
		<Store>
			<HeaderWithRouter/>
			<Switch>
				<Route path={`${path}/ingresar`} exact component={Login}/>
				<Route path={`${path}/registro`} exact component={Register}/>
				<Route path={`${path}/recuperar`} exact component={RecoverPass}/>
				<Route path={`${path}/recuperarpaso2`} exact component={RecoverPassStep2}/>
				<Route path={`${path}/cambiarpassword`} exact component={Changepass}/>
				<Route path={`${path}/`} exact component={Home} />
				<Route path={`${path}/results`} component={Result} />
				<Route path={`${path}/profile/reseacher/:university/:rut`} exact component={ResearcherProfile} />
				<Route path={`${path}/profile/hub/:university/:idHub`} component={HubProfile} />
				<Route path={`${path}/profile/technology/:university/:idTechnology`} component={TechnologyProfile}/>
				<Route path={`${path}/privacy`} exact component={PrivacyPoliciesBox} />    
				<Route path="*" exact component={Home} />
			</Switch>
			<Footer />
		</Store>
	);
}

export default App;
