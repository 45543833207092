import "./styles/IntelectualProperty.css";
import intelectualPropertyIcon from "../../../assets/icon_propiedad_intelectual.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";

interface Props {
	pi: {
		titulo: string;
		resumen: string | null;
		nro_solicitud: string | null;
		observaciones: string | null;
		anio_solicitud: string | null;
		pais_solicitud: string | null;
		solicitante_adicional: string | null; // Este campo es el de Autores en la version 1 del buscador
	}[];
}

const DropdownIcon = () => <img src={intelectualPropertyIcon} alt="Icon Propiedad intelectual" />;

export const IntelectualProperty = ({ pi }: Props): JSX.Element => {
	return (
		<DropDownWithIcon title={"PROPIEDAD INTELECTUAL"} icon={DropdownIcon}>
			<>
				{/* Esta condicion logica solo se ejecuta cuando pi deja de ser undefined */}
				{!!pi &&
					pi.map((item, index) => (
						<div
							key={item.titulo + index}
							className="dropdown-information-perfil-investigador dropdown-information-perfil-investigador information-grid"
						>
							<p className="text-blue-propiedad-intelectual text-blue-propiedad-intelectual-investigador">
								{item.titulo}
							</p>
							{item.solicitante_adicional && (
								<p className="current-text-propiedad-intelectual">
									<strong>Solicitante(s): </strong>
									{item.solicitante_adicional}
								</p>
							)}
							{item.nro_solicitud && (
								<p className="current-text-propiedad-intelectual">
									<strong>Nro. Solicitud/Concesión: </strong>
									{item.nro_solicitud}
								</p>
							)}
							{item.anio_solicitud && (
								<p className="current-text-propiedad-intelectual">
									<strong>Año de Solicitud: </strong>
									{item.anio_solicitud}
								</p>
							)}
							{item.pais_solicitud && (
								<p className="current-text-propiedad-intelectual">
									<strong>País: </strong>
									{item.pais_solicitud}
								</p>
							)}
							{item.resumen && (
								<p className="current-text-propiedad-intelectual">
									<strong>Resumen: </strong>
									{item.resumen}
								</p>
							)}
						</div>
					))}
			</>
		</DropDownWithIcon>
	);
};
