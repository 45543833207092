import linesOfInvestigationIcon from "../../../assets/icon_investigacion.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";
import "./styles/LinesOfInvestigation.css";
import React from "react";

const DropdownIcon = () => <img src={linesOfInvestigationIcon} alt="Icon Líneas de investigación" />;

interface LineOfInvestigation {
	aplicaciones: string | null;
	nombre_linea: string;
	descripcion: string | null;
	industrias: string | null;
}

interface Props {
	linesOfInvestigation: LineOfInvestigation[];
}

const LinesOfInvestigation = ({ linesOfInvestigation }: Props): JSX.Element => {
	return (
		<DropDownWithIcon title={"Líneas de investigación"} icon={DropdownIcon}>
			<div className="dropdown-information-perfil-investigador information-grid">
				{linesOfInvestigation.map(
					({ nombre_linea, descripcion, industrias}: LineOfInvestigation, index: number) => {
						if (nombre_linea && nombre_linea !== "") {
							return (

								<div className="body-lineas-de-investigacion-industrias" key={nombre_linea + index}>

									<div className="content-left-lineas-investigacion" >
										<p className="title-lineas-investigacion">{nombre_linea}</p>
										{descripcion && <p className="text-dropdown-generic">{descripcion}</p>}
									
									</div>	
									{ industrias && (
								
										<div className="content-right-investigacion">
											<p className="title-lineas-investigacion">Industrias:</p>
												{industrias.split(",").map((industria, index) => (
													<p className="item-industrias" key={industria + index}>
														{industria}
													</p>
												))}
										</div>
									
									)}
								
								</div>


								
							);
						}
							
							
						

						return <React.Fragment key={nombre_linea + index}></React.Fragment>;
					}
				)}
			</div>
		</DropDownWithIcon>
	);
};
export default LinesOfInvestigation;
