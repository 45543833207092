
import "./styles/SDGList.css";

import {SdgItem} from './SdgItem';
import { List, Stack } from "@mui/material";


const predefinedSDG: boolean[] = [];


export const SDGList = ({ sdg = predefinedSDG, index = 1 }) => {


    return (
        <section className="container-result-search-technology-2">
			<div className="column">
                <List disablePadding component={Stack} direction="row" spacing={2}>
    
                    
                        {
                            sdg.map((sdgElement, index) => { 
                                
                                return <SdgItem key={index} isLegible={sdgElement} index={index+1}/>
                            })
                        }
            
                </List>
            </div>
        </section>
      
        
    )

};