import { SyntheticEvent } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Path } from "../container/Results";
import styles from "./styles/TabsMenu.module.css";

import queryString from "query-string";

interface Props {
	url: string;
	handleChangeTabs: (e: SyntheticEvent, url: Path) => void;
}

export const TabsMenu = ({ url, handleChangeTabs }: Props) => {

	const location = useLocation();
	const { query }: any = queryString.parse(location.search);
	const { n_page }: any = queryString.parse(location.search);
	url = url.slice(-1) === '/' ? url.slice(0, -1) : url;
	return (
		<div className={styles["menu-category"]}>
			<NavLink
				to={`${url}/?query=${query}&n_page=${n_page}`}
				className={styles["menu-item"]}
				activeClassName={styles.active}
				exact={true}
				onClick={(e) => handleChangeTabs(e, Path.reseacher)}
			>
				Investigadores
			</NavLink>
			<NavLink
				to={`${url}/hub?query=${query}&n_page=${n_page}`}
				className={styles["menu-item"]}
				activeClassName={styles.active}
				exact={true}
				onClick={(e) => handleChangeTabs(e, Path.hub)}
			>
				Centros
			</NavLink>
			<NavLink
				to={`${url}/technology?query=${query}&n_page=${n_page}`}
				className={styles["menu-item"]}
				activeClassName={styles.active}
				exact={true}
				onClick={(e) => handleChangeTabs(e, Path.technology)}
			>
				Tecnologías
			</NavLink>
			<NavLink
				to={`${url}/service?query=${query}&n_page=${n_page}`}
				className={styles["menu-item"]}
				activeClassName={styles.active}
				exact={true}
				onClick={(e) => handleChangeTabs(e, Path.service)}
			>
				Servicios
			</NavLink>
			<NavLink
				to={`${url}/equipament?query=${query}&n_page=${n_page}`}
				className={styles["menu-item"]}
				activeClassName={styles.active}
				exact={true}
				onClick={(e) => handleChangeTabs(e, Path.equipament)}
			>
				Equipamientos
			</NavLink>
		</div>
	);
};
