import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const triggerSweetModal = (success: boolean, title: string, text?: string) => {
	const MySwal = withReactContent(Swal);

	if (success) {
		MySwal.fire({
			title,
			text,
			icon: "success",
			customClass: {
				confirmButton: "modal-confirmation-button",
			},
		});
	} else {
		MySwal.fire({
			title,
			text,
			icon: "error",
			customClass: {
				confirmButton: "modal-confirmation-button",
			},
		});
	}
};

export default triggerSweetModal;
