import "./styles/Projects.css";
import projectsIcon from "../../../assets/icon_folder_atom.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";
import useArrPaginator from "../../../hooks/useArrPaginator";
interface Props {
	projects: IProject[];
}

interface IProject {
	equipo_proyecto: string | null;
	vincula_empresa: string | null;
	anio_publicacion: string | null;
	rol_investigador: string | null;
	fuente_financiacion: string | null;
	proyecto: string;
}

const DropdownIcon = () => <img src={projectsIcon} alt="Icon Propiedad intelectual" />;

export const Projects = ({ projects }: Props): JSX.Element => {
	const { itemsToShow: projectsToShow, handleClick } = useArrPaginator<IProject>(projects);
	return (
		<DropDownWithIcon title={"PROYECTOS"} icon={DropdownIcon}>
			<div
				className="dropdown-information-perfil-investigador
        dropdown-information-perfil-investigador information-grid"
			>
				{!!projectsToShow &&
					projectsToShow.map((item, index) => (
						<span key={item.proyecto + index} className="information-perfil-investigador__wrap">
							<p className="text-blue-publicaciones-cientificas-investigador">{item.proyecto}</p>
							{item.rol_investigador && item.rol_investigador !== "" && (
								<p className="current-text-publicaciones-cientificas">
									<strong>Rol en el proyecto: </strong>
									{item.rol_investigador}
								</p>
							)}
							{item.fuente_financiacion && item.fuente_financiacion !== "" && (
								<p className="current-text-publicaciones-cientificas">
									<strong>Fuente de financiamiento: </strong>
									{item.fuente_financiacion}
								</p>
							)}
							{item.anio_publicacion && item.anio_publicacion !== "" && (
								<p className="current-text-publicaciones-cientificas">
									<strong>Periodo de vigencia:</strong> {item.anio_publicacion}
								</p>
							)}
							{index === projectsToShow.length - 1 && projectsToShow.length !== projects.length && (
								<button className="link-mas-publicaciones-cientificas" onClick={handleClick}>
									Ver más proyectos
								</button>
							)}
						</span>
					))}
			</div>
		</DropDownWithIcon>
	);
};
