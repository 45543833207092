import TagManager from "react-gtm-module";
import { useParams, useHistory } from "react-router-dom";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import "./styles/RecoverPass.css";
import { useState } from 'react';
import { Box, Button, Stack, TextField } from "@mui/material";
import { validarEmail } from "global/utils/validators";
import { Amplify, Auth } from 'aws-amplify';
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";

export const RecoverPass = () => {
	const { university = "" } = useParams<{ university: string }>();


	const [email, setEmail] = useState('');
	const [firstTry, setFirstTry] = useState(false);
	const [loginError, setLoginError] = useState('');
	const history = useHistory();
	const firstParam = useGetFirstUrlParam();

	const handlePasswordRecovery = async () => {
		try{
			const data = await Auth.forgotPassword(email);
			setLoginError('');
			history.push(`/${firstParam}/recuperarpaso2?user=${email}`);
		}catch(error){
			setLoginError(processForgotPasswordError(error));
		}
		
	};
	TagManager.dataLayer({
		dataLayer: {
			event: "visita",
			pagePath: "RecoverPass",
			university: translateUniversityAcronym(university) || university,
		},
	});
	const processForgotPasswordError = (error: any): string => {
		switch (error.code) {
		  case 'UserNotFoundException':
			return 'No se encontró un usuario con esa dirección de correo electrónico.';
		  case 'LimitExceededException':
			return 'Has excedido el límite de intentos. Por favor, espera un momento antes de intentar nuevamente.';
		  default:
			return 'Ha ocurrido un error inesperado. Por favor, intenta nuevamente.';
		}
	  };

	return (
		<Box
				className="home-component"
				sx={{ width: 400, marginTop: 3, marginBottom: 5}}
			>
				<div className="password-recovery-container">
					<h2>Recuperar contraseña</h2>
					
					<TextField error={firstTry && !validarEmail(email)} onBlur={() => setFirstTry(true)} fullWidth sx={{ mt: 1, mb: 1 }} id="outlined-basic" label="Correo electrónico" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
					{
						loginError === '' ?
							<></> :
							<p style={{ color: "red" }}>*{loginError}</p>
					}
					<Stack spacing={1} direction="row" justifyContent="center" marginTop={2}>
						<Button variant="contained" onClick={handlePasswordRecovery} style={{backgroundColor: "#FE894A"}}>Recuperar contraseña</Button>
					</Stack>
				</div>
		</Box>
	);
};
