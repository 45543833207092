import { useEffect, useState } from "react"
const _array = require('lodash/array')
/**
 * @description function that get a arr and return the arr paginated on each call 
 * @param { T } T interface generic
 * @param { Array } arr arr of type T
 * @returns { handleClick } function that add more items to show everytime that its called
 * @returns { itemsToShow } array paginated
 * 
 */
export function useArrPaginator<T>( arr: T[] ) {
  const numberPerPage = 10;
  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(numberPerPage)
  const [itemsToShow, setItemsToShow] = useState<T[]>(_array.take(arr,numberOfItemsToShow))
  const handleClick = () => {
    setNumberOfItemsToShow( numberOfItemsToShow + numberPerPage )
  }

  useEffect(() => {
    setItemsToShow( _array.take(arr,numberOfItemsToShow) )
  }, [numberOfItemsToShow, arr])
  
  return {
    itemsToShow,
    handleClick
  }
}

export default useArrPaginator;