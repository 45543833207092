import projectsIcon from "../../../assets/icon_proyectos.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";
import useArrPaginator from "../../../hooks/useArrPaginator";
import "./styles/Projects.css";
const DropdownIcon = () => <img src={projectsIcon} alt="Icon Proyectos" />;

interface Project {
	nombre: string;
	institucion_financia: string;
	miembro: string;
	anio_publicacion: string;
}

interface Props {
	projects: Project[];
}
export const Projects = ({ projects }: Props): JSX.Element => {
	const { itemsToShow: projectsToShow, handleClick } = useArrPaginator<any>(projects);

	return (
		<DropDownWithIcon title={"PROYECTOS"} icon={DropdownIcon}>
			<div
				className="dropdown-information-perfil-investigador
dropdown-information-perfil-investigador information-grid"
			>
				{projectsToShow.map(({ nombre, institucion_financia, anio_publicacion, miembro }: Project, index: number) => (
					<span key={nombre} className="projects-container-hub">
						<p className="text-blue-publicaciones-cientificas">{nombre}</p>
						{institucion_financia && (
							<p className="current-text-publicaciones-cientificas">
								<strong>Fuente de financiamiento: </strong>
								{institucion_financia}
							</p>
						)}
						{anio_publicacion && (
							<p className="current-text-publicaciones-cientificas">
								<strong>Periodo de vigencia : </strong>
								{anio_publicacion}
							</p>
						)}
						{/* {miembro && (
							<p className="current-text-publicaciones-cientificas">
								<strong>Miembro del equipo: </strong>
								{miembro}
							</p>
						)} */}
						{/* TODO: todo */}
						{/* <p className="current-text-publicaciones-cientificas">
              <strong>Año de adjudicación:</strong>XXXX
            </p>
            <p className="current-text-publicaciones-cientificas">
              <strong>Equipo de Investigación: </strong>Xxxxxxxxxxxx Xxxxxxxxx,
              Xxxxxxxxxxxx Xxxxxxxxx, Xxxxxxxxxxxx Xxxxxxxx
            </p> */}
						{index === projectsToShow.length - 1 && projectsToShow.length !== projects.length && (
							<button className="link-mas-publicaciones-cientificas" onClick={handleClick}>
								Ver más proyectos
							</button>
						)}
					</span>
				))}
			</div>
		</DropDownWithIcon>
	);
};
