// IMPORTANTE:  Este archivo de configuracion copió los componentes configurados en el archivo
//              aws-exports.js, pero se necesitaba configurar APIs de manera manual, por lo que
//              se hizo esta copia, porque el archivo original se genera automático y no se puede
//              pues los cambios de amplify pueden sobrescribir los cambios.
//              Por lo anterior, si el archivo original necesita ser cambiado, se deben considerar esos cambios acá.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_CLIENT_ID,
    "oauth": {
        "domain": process.env.REACT_APP_COGNITO_DOMAIN
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;