import Overview from "../components/Overview";
import Team from "../components/Team";
import { Partners } from "../components/Partners";
import LinesOfInvestigation from "../components/LinesOfInvestigation";
import { Projects } from "../components/Projects";
import { ScientificPosts } from "../components/ScientificPosts";
import Hero from "../components/Hero";
import { BackButton } from "global/components/BackButton";
import { useParams } from "react-router-dom";
import useSQL from "hooks/useSQL";
import SQLPaths from "global/utils/sqlPaths";
import "./styles/HubProfile.css";
interface HubUrlParams {
	university: string;
	idHub: string;
}

export function HubProfile() {
	const { university, idHub } = useParams<HubUrlParams>();

	const { response: hub, fetching } = useSQL(SQLPaths.hub, university, idHub);
	/**
	 * Verifica si existe almenos una linea de investigacion que tenga nombre_linea (titulo)
	 * @param linesOfInvestigation
	 * @returns true
	 * @returns false
	 */
	const hasTitle = (linesOfInvestigation: any): boolean => {
		return linesOfInvestigation.some((line: any) => line.nombre_linea);
	};

	/**
	 * Verifica que al menos alguno de los parametros no sea nulo o venga vacio
	 * @returns true
	 * @returns false
	 */
	const hasOverviewData = ({ descripcion, propuesta_valor, ventajas_competitivas, expertice}: any): boolean => {
		if (descripcion || propuesta_valor || ventajas_competitivas || expertice?.length) {
			return true;
		}
		
		return false;
	};

	return (
		<>
			<BackButton />
			<div id="hub-components-container">
				<Hero {...hub} />
				{!fetching && hasOverviewData(hub) && <Overview {...hub} />}
				{!fetching && hub.investigadores.length > 0 && (
					<Team teamMembers={hub.investigadores} university={hub.universidad} />
				)}
				{!fetching && hub.lineainvestigacion.length > 0 && hasTitle(hub.lineainvestigacion) && (
					<LinesOfInvestigation linesOfInvestigation={hub.lineainvestigacion} />
				)}
				{!fetching && hub.publicaciones.length > 0 && <ScientificPosts posts={hub.publicaciones} />}
				{!fetching && hub.proyectos.length > 0 && <Projects projects={hub.proyectos} />}
				{!fetching && hub.partners.length > 0 && <Partners partners={hub.partners} />}
			</div>
		</>
	);
}
