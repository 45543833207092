import "./styles/TechnologyItem.css";
import { ElasticFormatNotObligatory, ElasticFormatObligatory } from "global/interfaces/Elastic";
import { Fragment } from "react";

import { Link, useParams } from "react-router-dom";

interface Props {
	nombre: ElasticFormatObligatory;
	trl: ElasticFormatNotObligatory;
	id: ElasticFormatNotObligatory;
	universidad: ElasticFormatNotObligatory;
	withIa: Boolean;
	nota?: string;
	razon?: string;
}

const TechnologyItem = ({ nombre, trl, universidad, id, withIa, nota, razon }: Props) => {
	const { university = "" } = useParams<{ university: string }>();

	return (
		<div className="container-technologia">
			<div className="information-centro">
				<p className="nombre_servicio">{nombre?.raw}</p>
				{trl?.raw && (
					<p className="profile-item-investigador">
						<strong>TRL:</strong> {trl?.raw}
					</p>
				)}
				<Link
					className="contacto_link_item_servicio"
					to={{
						pathname: `/${university}/profile/technology/${universidad?.raw}/${id?.raw}`,
					}}
				>
					+ información
				</Link>
				{
					withIa && 
					<Fragment>
						<p className="profile-resume-investigador">Nivel de relación:{nota}</p>
						<p className="profile-resume-investigador">Justificación: {razon}</p>
					</Fragment>
				}
			</div>
		</div>
	);
};

export default TechnologyItem;
