import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useParams } from "react-router-dom";
import { ITabs } from "Result/tabs/shared/interfaces/TabsProps";
import { WrapperResults } from "Result/wrapper-results/WrapperResults";
import ResearcherItem from "../components/ResearcherItem";
import { IResearcherLS as Researcher } from "../interfaces/IResearcher";

interface Props extends ITabs {
	data: Researcher[];
}
export const ResearcherContainer = ({ data, error, loading, query }: Props) => {
	const { university } = useParams<{ university: string }>();

	useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: "visita",
				pagePath: "investigadores",
				university: university,
			},
		});
	}, [university]);
	
	const ResearcherResults = (): JSX.Element[] => {
		return data.map((researcherItem: Researcher, index: number) => {
			return (
				<React.Fragment key={researcherItem._meta.id}>
					<ResearcherItem {...researcherItem} />
				</React.Fragment>
			);
		});
	};

	return (
		<>
			<WrapperResults
				component={ResearcherResults}
				data={data}
				error={error}
				query={query}
				loading={loading}
			/>
		</>
	);
};
