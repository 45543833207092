import React, { PropsWithChildren, useState } from "react";
import useGetFirstUrlParam from "../../hooks/useGetFirstUrlParam";
import "./styles/DropdownWithoutIcon.css";


interface Props {
  title: string;
}

/* Example explained https://ozzie.sh/passing-icons-as-props-in-a-consistent-way-using-react */
export const DropDownWithoutIcon = ({
  title,
  children,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(true);

  const currentLocation = useGetFirstUrlParam();

  return (
    <>
      <section className="container-dropdown">
        <div className="dropdown" data-dropdown>
          <div className="flex-item-container" onClick={() => setOpen(!open)}>
            <button className="linkInvestigationLine" data-dropdown-button>
              {title}
            </button>
            <div className="chevrom-item">
              <img
                src={`/images/${currentLocation}/chevron_bottom.png`}
                alt="Chevron bottom orange"
              />
            </div>
          </div>
          {open && <>{children}</>}
        </div>
      </section>
    </>
  );
};
