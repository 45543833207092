import React from "react";
import ReactDOM from "react-dom";
import "@aws-amplify/ui-react/styles.css";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { WrapperRouter } from "global/routes/WrapperRouter";
// ANAlYTICS
import TagManager from "react-gtm-module";
import config from "config";
const tagManagerArgs = {
	//gtmId: 'GTM-TGN2WQV'
	gtmId: config.ANALYTICS_TAG,
};

TagManager.initialize(tagManagerArgs);
// END ANALYTICS
ReactDOM.render(
	<React.StrictMode>
		<WrapperRouter />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
