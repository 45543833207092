/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Footer.css";
import useGetFirstUrlParam from "../../hooks/useGetFirstUrlParam";
import universitiesInformation from "global/utils/universitiesInformation";
import { Link } from "react-router-dom";

export const Footer = () => {
	const folder: string = useGetFirstUrlParam();
	const scrollToTop = () => {
		window.scrollTo(0, 0)
	}
	let paramUniversity: string;

	if (folder === "caps" || folder === "udd" || folder === "ufro") {
		paramUniversity = folder;
	} else {
		paramUniversity = "caps";
	}

	const { link, emails, cellPhoneNumbers } = universitiesInformation[paramUniversity];

	return (
		<footer>
			<section className="footer-content">
				<div>
					{folder === "caps" && <p className="footer-text-logo">Desarrollado por:</p>}
					<a href={link} target="_blank" rel="noreferrer">
						<img className="footer-logo" src={`/images/${folder}/footer_logo.png`} alt={`Logo ${folder}`} />
					</a>
				</div>
				<div className="footer-div-links">
					{folder === "caps" ? (
						<>
							<p className="footer-title-div">Soporte CaPS:</p>
							<a href={`mailto:${emails[0]}`} className="footer-link-div" key={emails[0]}>
								{emails[0]}
							</a>
						</>
					) : (
						<>
							<p className="footer-title-div">Contacto {folder.toUpperCase()}:</p>
							{emails.map((email: string) => {
								return (
									<a href={`mailto:${email}`} className="footer-link-div" key={email}>
										{email}
									</a>
								);
							})}
						</>
					)}
					{cellPhoneNumbers.map((cellphoneNumber) => {
						return (
							<a href={`tel:${cellphoneNumber}`} className="footer-link-div" key={cellphoneNumber}>
								{cellphoneNumber}
							</a>
						);
					})}
				</div>
				<div className="footer-div-links">
					<p className="footer-title-div">Contacto HubTec:</p>
					<a href="mailto:contacto@hubtec.cl" className="footer-link-div">
						contacto@hubtec.cl
					</a>
					<a href="tel:+56933098864" className="footer-link-div">
						+56 9 3309 8864
					</a>
					{folder !== "caps" && (
						<>
							<p className="footer-title-div">Contacto Soporte CaPS:</p>
							<a href="mailto:soportecaps@hubtec.cl" className="footer-link-div">
								soportecaps@hubtec.cl
							</a>
						</>
					)}
				</div>
				<div className="footer-div-links">
					<p className="footer-title-div">Sobre HubTec</p>
					<a
						href="https://www.hubtec.cl/somos/"
						target="_blank"
						rel="noopener noreferrer"
						className="footer-link-div"
					>
						Quiénes somos
					</a>
					<a
						href="https://www.hubtec.cl/programas/"
						target="_blank"
						rel="noopener noreferrer"
						className="footer-link-div"
					>
						Programas
					</a>
					<a
						href="https://www.hubtec.cl/contacto/"
						target="_blank"
						rel="noopener noreferrer"
						className="footer-link-div"
					>
						Contacto
					</a>
					
					<span className="footer-link-div">
						<Link onClick={scrollToTop} to={{pathname:`/${folder}/privacy`}} target="_blank">Política de Privacidad </Link>
					</span>

				</div>
			</section>
		</footer>
	);
};
