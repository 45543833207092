import { useLocation } from "react-router-dom";
import { UniversityCases } from "global/interfaces/Universities";

const useGetFirstUrlParam = (): string => {
	const { pathname } = useLocation();

	const urlFirstParam = pathname.split("/", 2)[1].toLocaleLowerCase();

	if (urlFirstParam === UniversityCases.caps) {
		return UniversityCases.caps;
	} else if (urlFirstParam === UniversityCases.udd) {
		return UniversityCases.udd;
	} else if (urlFirstParam === UniversityCases.ufro) {
		return UniversityCases.ufro;
	} else if (urlFirstParam === UniversityCases.uandes) {
		return UniversityCases.uandes;
	} else if (urlFirstParam === UniversityCases.unab) {
		return UniversityCases.unab;
	} else if (urlFirstParam === UniversityCases.uv) {
		return UniversityCases.uv;
	} else if (urlFirstParam === UniversityCases.puc) {
		return UniversityCases.puc;
	} else if (urlFirstParam === UniversityCases.pucv) {
		return UniversityCases.pucv;
	} else {
		console.warn("Primer parametro de la url no valido");
		return "";
	}
};
export default useGetFirstUrlParam;
