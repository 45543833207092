import translateUniversityAcronym from "global/utils/translateUniversityAcronym";

import "./styles/Hero.css";

interface Props {
	nombre_centro: string;
	facultad: string;
	link_centro: string;
	universidad: string;
}

const Hero = ({ nombre_centro, facultad, link_centro, universidad }: Props) => {
	const fullNameUniversity = translateUniversityAcronym(universidad);

	return (
		<section className="container-hero-centro">
			<div className="container-hero-items">
				<div className="items-container">
					{link_centro && link_centro !== "" ? (
						<a href={link_centro} target="_blank" className="title-hero-centro" rel="noreferrer">
							{nombre_centro}
						</a>
					) : (
						<p className="title-hero-centro">{nombre_centro}</p>
					)}
					{facultad !== "" && <p className="text-hero-centro third-text-hero-centro">{facultad}</p>}
					{fullNameUniversity && fullNameUniversity !== "" && (
						<p className="text-hero-centro fourth-text-hero-centro ">{fullNameUniversity}</p>
					)}
				</div>
			</div>
		</section>
	);
};

export default Hero;
