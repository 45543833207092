import "./styles/ResearcherProfile.css";
import { useParams, useHistory } from "react-router-dom";
// import logoContacto from "../../../assets/logo_contacto.png";
import logoLinkedin from "../../../assets/logo_linkedin.png";
import logoAnid from "../../../assets/logo_anid.png";
import logoOrcid from "../../../assets/logo_orcid.png";
import logoContacto from "../../../assets/logo_contacto.png";
// import arrowOrange from "../../../assets/chevron_bottom_orange.png";
//const _array = require('lodash/array')
import { ScientificPosts } from "../components/ScientificPosts";
import { Projects } from "../components/Projects";
import { LinesOfInvestigation } from "../components/LinesOfInvestigation";
import { IntelectualProperty } from "../components/IntelectualProperty";
import { Overview } from "../components/Overview";
import { BackButton } from "global/components/BackButton";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SQLPaths from "global/utils/sqlPaths";
import useSQL from "hooks/useSQL";
import { axiosInstance } from "global/utils/axios";
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";
import ContactForm from "global/components/ContactForm";
import useGetFourthUrlParam from "hooks/useGetFourthUrlParam";
import { UserContext } from "auth/login/container/UserContext";
import { useContext } from "react";

interface IReseacherData {
	nombres: string;
	universidad: string;
	centro: string | null;
	area_de_expertise: { area: string }[];
	link_linked: string;
	id_orcid: string;
	investigadores_anid: [];
	proyectos: [];
	pi: []; // Significa Propiedad Intelectual y es el valor de "Patentes" dentro del perfil del investigador
	email: string;
	incorporar_link_portal_del_investigador_anid: string;
	link_centro: string;
	investigacion: [];
	estudios: any;
	rut: any;
	id: number;
	words: [];
}

export const ResearcherProfile = (): JSX.Element => {
	const { rut: idparam, university } = useParams<{
		rut: string;
		university: string;
	}>();

	const currentLocationWitdDomain = window.location.href;

	const currentLocation = useGetFirstUrlParam();

	const currentOrigin = useGetFourthUrlParam();

	const Login = useContext(UserContext);

	const history = useHistory();

	const triggerSweetModal = (success: boolean, message: string, email?: string) => {
		const MySwal = withReactContent(Swal);

		if (success) {
			MySwal.fire({
				title: "Correo enviado exitosamente",
				text: message,
				icon: "success",
				customClass: {
					confirmButton: "modal-confirmation-button",
				},
			});
		} else {
			MySwal.fire({
				title: "Correo no enviado",
				text: message,
				icon: "error",
				customClass: {
					confirmButton: "modal-confirmation-button",
				},
			});
		}
	};

	const sendEmail = (email: string, id: string) => {
		const succesMessage: string = `Se ha enviado un formulario de solicitud de actualización de tu perfil a tu correo ****@***.com`;

		const timeErrorMessage: string = `Lo sentimos, no podemos atender a su solicitud en este momento. 
		Si usted ya generó una solicitud de edición de perfil, por favor revise su correo. 
		En 48 horas se habilitará la función nuevamente`;

		const genericErrorMessage: string = `Lo sentimos, no podemos procesar su solicitud en estos momentos`;

		axiosInstance
			.post(`/send-email/${currentLocation}/${id}`)
			.then(({ status }) => {
				if (status === 200) {
					triggerSweetModal(true, succesMessage, email);
				}
				// else if (status === 403) {
				// 	triggerSweetModal(true, timeErrorMessage, email);
				// }
				else {
					triggerSweetModal(false, timeErrorMessage);
				}
			})
			.catch((error) => {
				triggerSweetModal(false, timeErrorMessage);
			});
	};

	const checkSome = (investigacion: any): boolean => {
		return investigacion.some((line: any) => line.nombre_de_la_linea_de_investigacion);
	};

	const { response }: any = useSQL(SQLPaths.researcher, university, idparam);

	const {
		nombres,
		universidad,
		centro,
		area_de_expertise = [],
		link_linked,
		id_orcid,
		investigadores_anid,
		proyectos,
		pi,
		email,
		incorporar_link_portal_del_investigador_anid,
		link_centro,
		investigacion = [],
		estudios,
		id,
		words
	}: IReseacherData = response;
	const fullNameUniversity = translateUniversityAcronym(universidad);

	const getAreaOfExpertiseWithComma = (areaOfExpertise: { area: string }[]): string => {
		if (!areaOfExpertise) {
			return "";
		}
		let areaOfExpetiseString: string = "";
		areaOfExpertise.forEach(({ area }, index: number) => {
			if (index > 0) areaOfExpetiseString += ", ";
			areaOfExpetiseString += area;
		});
		return areaOfExpetiseString;
	};

	return (
		<div className="researcher-components-container">
			<BackButton />
			<hr style={{ marginTop: "10px" }} />
			<section className="profile-investigador">
				<div className="profile-card-investigador">
					<div className="profile-card-data-investigador">
						<div className="container-profile-picture-investigador">
							<img
								src={`/avatar/${id}.jpg`}
								alt="Profile card investigador"
								onError={(e: any) => {
									e.target.onerror = null;
									e.target.style.maxWidth = "200px";
									e.target.src =
										"https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fzancada.com%2Fwp-content%2Fimagenes%2F2012%2F12%2Ffacebook-profile-picture-n-copy.jpg&f=1&nofb=1";
								}}
							/>
						</div>
						<div className="container-profile-card-data-investigador">
							<p className="profile-name-investigador">{nombres}</p>
							{fullNameUniversity && (
								<p className="profile-data-investigador">
									<strong>Universidad: </strong>
									{fullNameUniversity}
								</p>
							)}

							{centro && centro !== "" && (
								<p className="profile-data-investigador">
									<strong>Centro: </strong>
									<a href={link_centro} target="_blank" rel="noreferrer" className="link-centro-investigador">
										{centro}
									</a>
								</p>
							)}

							{area_de_expertise.length > 0 && (
								<p className="profile-data-investigador">
									<strong>Área de expertise: </strong>
									{getAreaOfExpertiseWithComma(area_de_expertise)}
								</p>
							)}
						</div>
					</div>
					<UserContext.Provider value={Login}>
						{Login.user && (Login.user as any).attributes["custom:id_investigador"]===idparam?
							<>
								<div className="profile-edit-investigador">
									<p>¿Quieres editar tu perfil?</p>
									<p className="profile-edit-investigador-button" onClick={() => sendEmail((Login.user as any).username, idparam)}>
										Haz click aquí
									</p>
								</div>
							</>
							:
							<>
								
							</>
						}
						{
							Login.user?
							<>
								
							</>
							:
							<>
								<div className="profile-edit-investigador">
									<p>¿Quieres editar tu perfil?</p>
									<p className="profile-edit-investigador-button" onClick={() => history.push(`/${currentLocation}/registro`)}>
										Regístrate aquí
									</p>
									
								</div>
							</>
						}
					</UserContext.Provider>
					{/*email && (
						<div className="profile-edit-investigador">
							<p>¿Quieres editar tu perfil?</p>
							<p className="profile-edit-investigador-button" onClick={() => sendEmail(email, idparam)}>
								Haz click aquí
							</p>
						</div>
					)*/}
				</div>
				<div className="profile-card-social-investigador">
					{incorporar_link_portal_del_investigador_anid && (
						<div className="item-profile-investigador">
							<img src={logoAnid} alt="ANID" />
							<a href={incorporar_link_portal_del_investigador_anid} target="_blank" rel="noreferrer">
								Perfil ANID
							</a>
						</div>
					)}
					{link_linked && (
						<div className="item-profile-investigador">
							<img src={logoLinkedin} alt="Linkedin" />
							<a href={link_linked} target="_blank" rel="noreferrer">
								Perfil Linkedin
							</a>
						</div>
					)}
					{id_orcid && (
						<div className="item-profile-investigador">
							<img src={logoOrcid} alt="ORCID" />
							<a href={id_orcid} target="_blank" rel="noreferrer">
								ORCID
							</a>
						</div>
					)}
					{Login.user && nombres && (
						<div className="item-profile-investigador">
							<img src={logoContacto} alt="Contacto" />
							<ContactForm
								currentLocation={currentLocation}
								location={currentLocationWitdDomain}
								origin={currentOrigin as any}
								dimension="Investigadores"
								openModalElementText="Contacto"
								openModalElementClassName="item-profile-investigador-contact-form-button"
							/>
						</div>
					)}
				</div>
				<div className="profile-card-data-number-investigador">
					<div className="proyects-item-profile-investigador">
						<p className="number-proyect-profile-investigador">
							{investigadores_anid && investigadores_anid.length > 0 ? investigadores_anid.length : 0}
						</p>
						<p className="text-proyect-profile-investigador">Publicaciones científicas</p>
					</div>
					<div className="proyects-item-profile-investigador">
						<p className="number-proyect-profile-investigador">
							{proyectos && proyectos.length > 0 ? proyectos.length : 0}
						</p>
						<p className="text-proyect-profile-investigador">Proyectos</p>
					</div>
					{/* <div className="proyects-item-profile-investigador">
            <p className="number-proyect-profile-investigador">0</p>
            <p className="text-proyect-profile-investigador">
              Perfiles Similares
            </p>
          </div> */}
					<div className="proyects-item-profile-investigador">
						<p className="number-proyect-profile-investigador">{pi && pi.length > 0 ? pi.length : 0}</p>
						<p className="text-proyect-profile-investigador">Patentes</p>
					</div>
				</div>
			</section>
			{!!estudios && estudios.items > 0 && <Overview studies={estudios} />}
			{words?.length > 0 && (
				<LinesOfInvestigation investigaciones={investigacion} words={words} />
			)}
			{!!pi && pi.length > 0 && <IntelectualProperty pi={pi} />}
			{!!investigadores_anid && investigadores_anid.length > 0 && (
				<ScientificPosts posts={investigadores_anid} />
			)}
			{!!proyectos && proyectos.length > 0 && <Projects projects={proyectos} />}
		</div>
	);
};
