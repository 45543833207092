import { axiosInstance } from "global/utils/axios";
import { useEffect, useState } from "react";
import SQLPaths from "global/utils/sqlPaths";

/**
 * Makes a API call to the backend to get the data from the SQL database.
 * @param path
 * @param university
 * @param id
 */
const useSQL = (path: SQLPaths, university: string, id: string): any => {
	const [state, setState] = useState<any>({ response: {}, fetching: true });

	useEffect(() => {
		axiosInstance
			// Example url: "/researcher/UDD/10" or "/hub/UNAB/5" or "/technology/PUVC/30"
			.get<any>(`${path}/${university}/${id}`)
			.then(({ data }) => {
				if (data.status === 400) {
					console.error(data.error);
				}

				if (data.response.hasOwnProperty("researcher")) {
					setState({
						response: data.response.researcher[0],
						fetching: false,
					});
				}

				if (data.response.hasOwnProperty("centro")) {
					setState({
						response: data.response.centro[0],
						fetching: false,
					});
				}

				if (data.response.hasOwnProperty("technology")) {
					setState({
						response: data.response.technology[0],
						fetching: false,
					});
				}
			})
			.catch((error) => console.error(error));
	}, [path, university, id]);

	return state;
};

export default useSQL;
