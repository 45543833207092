import React, { PropsWithChildren, useState } from "react";
import useGetFirstUrlParam from "../../hooks/useGetFirstUrlParam";
import "./styles/DropdownWithIcon.css";

interface IconTypeProps {
  width?: number;
  height?: number;
}

type IconType = (props: IconTypeProps) => JSX.Element;

interface Props {
  title: string;
  icon: IconType;
}

/* Example explained https://ozzie.sh/passing-icons-as-props-in-a-consistent-way-using-react */
export const DropDownWithIcon = ({
  title,
  icon,
  children,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(false);

  const currentLocation = useGetFirstUrlParam();

  return (
    <>
      <section className="container-dropdown-investigador">
        <div className="dropdown" data-dropdown>
          <div className="flex-item-container" onClick={() => setOpen(!open)}>
            <div>{React.createElement(icon)}</div>
            <button className="linkInvestigationLine" data-dropdown-button>
              {title}
            </button>
            <div className="chevrom-item">
              <img
                src={`/images/${currentLocation}/chevron_bottom.png`}
                alt="Chevron bottom orange"
              />
            </div>
          </div>
          {open && <>{children}</>}
        </div>
      </section>
    </>
  );
};
