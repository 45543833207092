import "./styles/LinesOfInvestigation.css";
import investigationIcon from "../../../assets/icon_investigacion.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";


import ReactWordcloud from 'react-wordcloud';
import { Resizable } from "re-resizable";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

import { OptionsProp } from 'react-wordcloud';

type Props = {
	investigaciones: {
		otros: string;
		sector: string;
		industrias: string;
		obtener_financiamiento_privado: string;
		nombre_de_la_linea_de_investigacion: string;
		creputacion_prestigio_para_la_universidad: string;
		potencial_de_obtener_financiamiento_publico: string;
		permitir_abordar_desafios_o_problemas_de_la_industria: string;
		conocimiento_generado_podria_ser_utilizado_para_resolver_problemas: string;
		desarrollo_soluciones_que_permitan_abordar_los_desafios_o_problematicas: string;
		
	}[];
	words:[];
};



const options: OptionsProp = {
	colors: ["#318CE7", "#4a9ae9", "#6aa9e9", "#98c3ed", "#afcdea"],
	enableTooltip: false,
	fontFamily: "britannic",
	fontSizes: [16, 60],
	fontStyle: "normal",
	fontWeight: "700",
	padding: 1,
	rotations: 3,
	rotationAngles: [0, 0],
	spiral: "rectangular",
	scale: "linear",
  };
  

const DropdownIcon = () => <img src={investigationIcon} alt="Icon Líneas de investigación" />;

export const LinesOfInvestigation = ({ investigaciones, words }: Props): JSX.Element => {
	return (
		<DropDownWithIcon title={"Líneas de investigación"} icon={DropdownIcon}>
			<>
				<div className="dropdown-information-perfil-investigador information-grid">
					{/*  */}
					{investigaciones.map(
						(
							{
								desarrollo_soluciones_que_permitan_abordar_los_desafios_o_problematicas,
								industrias,
								nombre_de_la_linea_de_investigacion,
							},
							index
						) => (
							<div
								className="body-lineas-de-investigacion-industrias"
								key={nombre_de_la_linea_de_investigacion + index}
							>
								<div className="content-left-lineas-investigacion">
									{/* Title line */}
									{desarrollo_soluciones_que_permitan_abordar_los_desafios_o_problematicas ? (
										<p className="title-lineas-investigacion">{nombre_de_la_linea_de_investigacion}:</p>
									) : (
										<p className="title-lineas-investigacion">{nombre_de_la_linea_de_investigacion}</p>
									)}
									{/* END Title line */}
									<p className="text-dropdown-generic">
										{desarrollo_soluciones_que_permitan_abordar_los_desafios_o_problematicas}
									</p>
								</div>
								{/* sidebard with industries */}
								{industrias && (
									<div className="content-right-investigacion">
										<p className="title-lineas-investigacion">Industrias:</p>
										{industrias.split(",").map((industria, index) => (
											<p className="item-industrias" key={industria + index}>
												{industria}
											</p>
										))}
									</div>
								)}
								{/* END sidebard with industries */}
							</div>
						) //end componentent mapping
					)}

					<div className="body-word-cloud">
							<p className="title-word-cloud">Palabras clave</p>
							<div className="word-cloud-container">
								<ReactWordcloud options={options} words={words}/>
							</div>

					</div>
				</div>
			</>
		</DropDownWithIcon>
	);
};
