import scientificPosts from "../../../assets/icon_paper_pencil.png";
import { DropDownWithIcon } from "global/components/DropDownWithIcon";
import useArrPaginator from "../../../hooks/useArrPaginator";
import AbstractModal from "global/components/AbstractModal";
import "./styles/ScientificPosts.css";
const DropdownIcon = () => <img src={scientificPosts} alt="Icon Publicaciones científicas" />;

interface Post {
	anio: number;
	autor: string | "";
	nombre: string;
	revista: string | "";
	resumen?: string;
	miembro: string | "";
}

interface Props {
	posts: Post[];
}

export const ScientificPosts = ({ posts }: Props): JSX.Element => {
	const { itemsToShow: postsToShow, handleClick } = useArrPaginator<any>(posts);

	return (
		<DropDownWithIcon title={"PUBLICACIONES CIENTÍFICAS"} icon={DropdownIcon}>
			<div className="dropdown-information-perfil-investigador dropdown-information-perfil-investigador information-grid">
				{postsToShow.map(({ anio, autor, nombre, revista, resumen, miembro }: Post, index: number) => (
					<span key={nombre} className="scientific-post-container">
						<p className="text-blue-publicaciones-cientificas text-blue-publicaciones-cientificas--hub">
							{nombre}
						</p>
						{autor && autor !== "" && (
							<p className="current-text-publicaciones-cientificas">
								<strong>Autor(es): </strong>
								{autor}
							</p>
						)}
						{/* {miembro && miembro !== "" && (
							<p className="current-text-publicaciones-cientificas">
								<strong>Miembro del equipo: </strong>
								{miembro}
							</p>
						)} */}
						{anio && (
							<p className="current-text-publicaciones-cientificas">
								<strong>Publicado en: </strong>
								{anio}
							</p>
						)}
						{revista && revista !== "" && (
							<p className="current-text-publicaciones-cientificas">
								<strong>Revista: </strong>
								{revista}
							</p>
						)}
						{resumen && (
							<AbstractModal
								title={nombre}
								// TODO: esperar a que suban el resumen
								resume={resumen}
								buttonName="Leer resumen"
							/>
						)}
						<div>
							{index === postsToShow.length - 1 && postsToShow.length !== posts.length && (
								<button className="link-mas-publicaciones-cientificas" onClick={() => handleClick()}>
									Ver más publicaciones
								</button>
							)}
						</div>
					</span>
				))}
			</div>
		</DropDownWithIcon>
	);
};
