import React, { createContext, useContext, useState } from 'react';


export const Login = {
    user: null,
}

export const UserContext = createContext(Login);

export const useUserContext = () => useContext(UserContext);

