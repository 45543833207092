import { setWithoutExpiry } from "global/utils/localStorageUtils";
import { FilterAction } from "../interfaces/ActionsType";
import { IState } from "../interfaces/StateInterface";
const Reducer = (state: IState, action: FilterAction): IState => {
	switch (action.type) {
		case "[filter] update filters selected":
			const objToUpdate = {
				...state,
				fieldsSelected: state.fieldsSelected.map((fieldSelected) => {
					if (fieldSelected.title !== action.payload.title) {
						return fieldSelected;
					}
					// updating only the facets that match with the payload
					return {
						...fieldSelected,
						facets: action.payload.facets,
					};
				}),
			};

			setWithoutExpiry('fieldsSelected', objToUpdate.fieldsSelected);
			setWithoutExpiry('facets', objToUpdate.facets);
			setWithoutExpiry('typeSearch', objToUpdate.typeSearch);
			return objToUpdate;
		case "[filter] load filters":
			const objToLoad = {
				...state,
				facets: action.payload,
			};

			setWithoutExpiry('fieldsSelected', objToLoad.fieldsSelected);
			setWithoutExpiry('facets', objToLoad.facets);
			setWithoutExpiry('typeSearch', objToLoad.typeSearch);
			return objToLoad;
		case "[filter] reset selected filters":
			const objToResetSelected = {
				...state,
				fieldsSelected: state.fieldsSelected.map((fieldSelected) => {
					// updating only the facets that match with the payload
					return {
						...fieldSelected,
						facets: [],
					};
				}),
			};

			setWithoutExpiry('fieldsSelected', objToResetSelected.fieldsSelected);
			setWithoutExpiry('facets', objToResetSelected.facets);
			setWithoutExpiry('typeSearch', objToResetSelected.typeSearch);

			return objToResetSelected;
		case "[filter] reset filters":
			const objToReset = {
				...state,
				fieldsSelected: [{title: 'undefined', facets: []}],
				facets: {
					undefined:
					[
						{
							uuid: "NaN",
							type: "undefined",
							name: "undefined",
							data: []
						}
					]
				},
			};
			setWithoutExpiry('fieldsSelected', objToReset.fieldsSelected);
			setWithoutExpiry('facets', objToReset.facets);
			setWithoutExpiry('typeSearch', objToReset.typeSearch);
			return objToReset;
		case "[typesearch] change typesearch":
			const objTypeSearchReset = {
				...state,
				typeSearch: action.payload.typeSearch
			};
			setWithoutExpiry('fieldsSelected', objTypeSearchReset.fieldsSelected);
			setWithoutExpiry('facets', objTypeSearchReset.facets);
			setWithoutExpiry('typeSearch', objTypeSearchReset.typeSearch);
			return objTypeSearchReset;
		default:
			return state;
	}
};
export default Reducer;
