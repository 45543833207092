import { FilterAction } from "global/reduxState/interfaces/ActionsType";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Field } from "../interfaces/Researcher";
import "./styles/Filter.css";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { getWithoutExpiry, setWithoutExpiry } from "global/utils/localStorageUtils";


interface Props {
	title: string;
	fields: Field[];
	showTitle?: boolean;
	dispatch: (object: any) => void;
}
export const Filter = ({ title, fields, showTitle, dispatch }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const { register, watch } = useForm();
	const handleClickDropdown = () => {
		setIsOpen(!isOpen);
	};

	const location = useLocation();
	const currentLocation = location.pathname;

	const history = useHistory();
	useEffect(() => {
		const subscription = watch(({ facets }) => {
			const selectedFacets: string[] = [];
			/**
			 * @description This sync the selected inputs with the fields
			 * @cause you cannot declare facets.${value.name} because this cause a overlapping between the values of the controlform
			 * and the values of the fields
			 */


			for (let index = 0; index < facets.length; index++) {
				//If the facet in the position x is selected we find the value of the fields in position x to make it match
				const facetValue = facets[index];
				if (facetValue) {
					// we only save the values (names) of the filter
					selectedFacets.push(fields[index].value);
				
					
				}
			}

			const action: FilterAction = {
				type: "[filter] update filters selected",
				payload: { title: title, facets: selectedFacets },
			};

			let values : any = queryString.parse(location.search, {sort: false});

			if(selectedFacets.length === 0){
				delete values[title];
			}else{
				values[title] = selectedFacets;
			}
            
			if(title === 'universidades'){
				values = (({ query, n_page, universidades }) => ({ query, n_page, universidades }))(values);
				if(!values.universidades){
					delete values['universidades'];
				}
			}
            const url = Object.keys(values).map((k) =>{
                let textParsed = '';
				textParsed = Array.isArray(values[k]) ? values[k].join('*') : values[k];
				if(k === 'n_page'){
					textParsed = "1";
				}
                return encodeURIComponent(k) + '=' + encodeURIComponent(textParsed);
            }).join('&')

			if(title === 'universidades'){
				setWithoutExpiry('prevUniversitiesSelected', getWithoutExpiry('universitiesSelected'));
				setWithoutExpiry('universitiesSelected', selectedFacets);
			}else{
				setWithoutExpiry('prevUniversitiesSelected', getWithoutExpiry('universitiesSelected'));
			}

			history.push(`${currentLocation}?${url}`);

			dispatch(action);
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [watch, dispatch, title, fields]);
	return fields.length > 0 ? (
		<>
			{showTitle && <p className="title-filter">Búsqueda Avanzada</p>}
			{
				<div className={`dropdown ${isOpen && "active"}`} data-dropdown>
					{
						<>
							<button className="link" data-dropdown-button onClick={handleClickDropdown}>
								{title}
							</button>
							<div className="dropdown-menu information-grid">
								{fields.map((facet: Field, index) => {
									return (
										<div className="dropdown-links" key={index}>
											<div className="checkbox-container sub-link">
												<input
													type="checkbox"
													id={facet.value}
													key={facet.value}
													checked={facet.checked}
													/* !warning dont change this line below */
													{...register(`facets.${index}`)}
												/>
												<label htmlFor={facet.value}>{facet.value}</label>
												<p className="number-per-filter">{facet.count}</p>
											</div>
										</div>
									);
								})}
							</div>
						</>
					}
				</div>
			}
		</>
	) : (
		<> </>
	);
};
