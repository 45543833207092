import { Link } from "react-router-dom";
import { Team as Equipo, AreaOfExpertise } from "global/interfaces/Technology";
import useGetFirstUrlParam from "../../../../hooks/useGetFirstUrlParam";
import "./styles/Team.css";

interface Props {
	equipo: Equipo[];
}

const Team = ({ equipo }: Props) => {
	/**
	 * Formatea las areas de expertise para que se puedan mostrar en el componente y separa cada area por comas, cuando
	 * se alcanza el ultimo elemento o solo hay 1 elemento, la funcion no agrega coma al final
	 * @param areas : AreaOfExpertise[]
	 * @returns string | string[]
	 */
	const formatAreaOfExpertise = (areas: AreaOfExpertise[]) => {
		if (areas.length < 1) {
			return areas[0].area;
		}

		const lastIndexOfAreas = areas.length - 1;

		const formattedAreas = areas.map((area: any, index: number) => {
			if (index === lastIndexOfAreas) {
				return area.area;
			}

			return area.area + ", ";
		});

		return formattedAreas;
	};

	const urlLocation = useGetFirstUrlParam();

	return (
		<div className="technology-item-container">
			<p className="title-item-tecnologia">Equipo:</p>
			{equipo.map(
				(
					{
						nombre,
						universidad,
						rut,
						area_de_expertise,
						centro,
						facultad,
						centro_existe,
						investigador_existe,
						id_centro,
						id_investigador,
					}: Equipo,
					index: number
				) => (
					<div className="profile-investigador-tecnologia" key={nombre + index}>
						<img
							src={`/avatar/${rut}.jpg`}
							className="profile-picture-investigador"
							alt="profile researcher"
							onError={(e: any) => {
								e.target.onerror = null;
								e.target.src =
									"https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fzancada.com%2Fwp-content%2Fimagenes%2F2012%2F12%2Ffacebook-profile-picture-n-copy.jpg&f=1&nofb=1";
							}}
						/>
						<div className="profile-information-investigador">
							{investigador_existe ? (
								<Link
									className="profile-name-investigador-tecnologia"
									to={`/${urlLocation}/profile/reseacher/${universidad}/${id_investigador}`}
								>
									{nombre}
								</Link>
							) : (
								<p className="profile-name-investigador-tecnologia no-underline">{nombre}</p>
							)}
							{universidad && (
								<p className="profile-item-investigador">
									Universidad: <strong>{universidad}</strong>
								</p>
							)}
							{facultad && (
								<p className="profile-item-investigador">
									Facultad: <strong>{facultad}</strong>
								</p>
							)}
							{centro && centro_existe === 1 && (
								<p className="profile-item-investigador">
									Centro:{" "}
									<strong>
										<Link
											className="link-centro-profile-investigador"
											to={`/${urlLocation}/profile/hub/${universidad}/${id_centro}`}
										>
											{centro}
										</Link>
									</strong>
								</p>
							)}
							{centro && centro_existe === 0 && (
								<p className="profile-item-investigador">
									Centro:{" "}
									<strong>
										<p className="link-centro-profile-investigador no-underline">{centro}</p>
									</strong>
								</p>
							)}
							{area_de_expertise && area_de_expertise.length > 0 && (
								<p className="profile-item-investigador">
									Área de expertise: <strong>{formatAreaOfExpertise(area_de_expertise)}</strong>
								</p>
							)}
						</div>
					</div>
				)
			)}
		</div>
	);
};

export default Team;
