import { getWithoutExpiry } from "global/utils/localStorageUtils";
import { createContext, useReducer } from "react";
import { FilterAction } from "../interfaces/ActionsType";
import { IState } from "../interfaces/StateInterface";
import Reducer from "../reducers";
const initialstate = {
	fieldsSelected: getWithoutExpiry('fieldsSelected') || [
		{
			title: "universidades",
			facets: [],
		},
		{
			title: "facultades",
			facets: [],
		},
		{
			title: "TRL",
			facets: [],
		},
		{
			title: "industrias",
			facets: [],
		},
		{
			title: "industrias_filtro",
			facets: [],
		},
		{
			title: "Estado de la PI",
			facets: [],
		},
		{
			title: "centros",
			facets: [],
		},
	],
	facets: getWithoutExpiry('facets') || {},
	typeSearch: 'keywords'
};

const Store = ({ children }: any) => {
	const [state, dispatch] = useReducer(Reducer, initialstate);
	return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};
export const Context = createContext<[IState, React.Dispatch<FilterAction>]>([initialstate, () => {}]);

export default Store;
