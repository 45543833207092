import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Equipament } from "../../../interfaces/Equipament";
import ContactForm from "global/components/ContactForm";
import translateUniversityAcronym from "global/utils/translateUniversityAcronym";
import "./styles/EquipamentItem.css";
import { Fragment, useContext } from "react";
import { UserContext } from "auth/login/container/UserContext";

const EquipamentItem = ({
	nombre,
	marca,
	modelo,
	facultad,
	universidad,
	centro,
	id_centro,
	descripcion,
	laboratorio,
	centro_existe,
	id,
	withIa,
	nota,
	razon
}: Equipament) => {
	const { university: currentLocation } = useParams<any>();

	const fullNameUniversity: string | null = translateUniversityAcronym(universidad?.raw);

	const equipamentNameAndId = nombre.raw + " / " + id.raw;

	const Login = useContext(UserContext);

	return (
		<div className="container-equipamento">
			<div>
				<p className="nombre_equipamento">{nombre?.raw}</p>
				<hr />
				{marca?.raw && (
					<div className="item_equipamento">
						<p className="name_item_equipamento bold">Marca: </p>{" "}
						<p className="name_item_equipamento">{marca.raw}</p>
					</div>
				)}
				{modelo?.raw && (
					<div className="item_equipamento">
						<p className="name_item_equipamento bold">Modelo: </p>{" "}
						<p className="name_item_equipamento">{modelo.raw}</p>
					</div>
				)}
				{descripcion?.raw && (
					<div className="item_equipamento-descripcion">
						<p className="name_item_equipamento bold">Descripción: </p>{" "}
						<p className="name_item_equipamento-descripcion">{descripcion.raw}</p>
					</div>
				)}
				{laboratorio?.raw && (
					<div className="item_equipamento">
						<p className="name_item_equipamento bold">Laboratorio: </p>{" "}
						<p className="name_item_equipamento">{laboratorio.raw}</p>
					</div>
				)}
				{centro?.raw && (
					<div className="item_equipamento">
						<p className="name_item_equipamento bold">Centro: </p>{" "}
						{centro_existe?.raw === "1" ? (
							<Link
								className="link_item_equipamento"
								to={{
									pathname: `/${currentLocation}/profile/hub/${universidad?.raw}/${id_centro?.raw}`,
								}}
							>
								{centro.raw}
							</Link>
						) : (
							<p className="link_item_equipamento-doesnt-exist">{centro.raw}</p>
						)}
					</div>
				)}
				{facultad?.raw && (
					<div className="item_equipamento">
						<p className="name_item_equipamento bold">Facultad: </p>{" "}
						<p className="name_item_equipamento">{facultad.raw}</p>
					</div>
				)}
				{fullNameUniversity && (
					<div className="item_equipamento">
						<p className="name_item_equipamento bold">Universidad: </p>{" "}
						<p className="name_item_equipamento">{fullNameUniversity}</p>
					</div>
				)}
				{Login.user &&

					<ContactForm
						currentLocation={currentLocation}
						location={equipamentNameAndId}
						origin={currentLocation}
						dimension="Equipamientos"
						openModalElementText="Contacto"
						openModalElementClassName="contacto_link_item_equipamento-equipamento"
						openModalElementAsButton={true}
					/>
				}
				{
					withIa &&
					<Fragment>
						<p className="profile-resume-investigador">Nivel de relación:{nota}</p>
						<p className="profile-resume-investigador">Justificación: {razon}</p>
					</Fragment>
				}
			</div>
		</div>
	);
};

export default EquipamentItem;
