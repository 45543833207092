import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./styles/ContactForm.css";
import { Checkbox, FormGroup, Grid } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { axiosInstance } from "global/utils/axios";
import triggerSweetModal from "global/utils/triggerSweetModal";
import { Link } from "react-router-dom";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "100%",
	maxWidth: 700,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

interface Props {
	openModalElementText: string;
	openModalElementClassName: string;
	openModalElementAsButton?: boolean;
	currentLocation: string;
	origin: string;
	location: string;
	dimension: "Investigadores" | "Tecnologías" | "Servicios" | "Equipamientos";
}

type Inputs = {
	firstName: string;
	lastName: string;
	institutionalEmail: string;
	institutionName: string;
	job: string;
	comment: string;
	polityCheck: boolean;
};

const ContactForm = ({
	openModalElementClassName,
	openModalElementText,
	openModalElementAsButton = false,
	currentLocation,
	origin,
	location,
	dimension,
}: Props) => {
	const [open, setOpen] = useState(false);
	const [checkboxStatus, setCheckboxStatus] = useState(true);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {setCheckboxStatus(true); setOpen(false);};

	

	const {
		register,
		handleSubmit,
		formState: { isValid },
	} = useForm<Inputs>({
		mode: "onChange",
	});

	const scrollToTop = () => {
		window.scrollTo(0, 0)
	}

	const sendContactForm: SubmitHandler<Inputs> = (data): void => {
		axiosInstance
			.post(`/send-contact-form`, { ...data, origin, location, dimension, currentLocation })
			.then(({ status }) => {
				if (status === 200) {
					triggerSweetModal(true, "Correo enviado exitosamente");
				} else {
					triggerSweetModal(false, "Correo no enviado");
				}
			})
			.catch((error) => {
				console.log("🚀 ~ file: ContactForm.tsx ~ line 72 ~ error", error);
				triggerSweetModal(false, "Correo no enviado");
			});
	};
	return (
		<div className="contact-form-modal-container">
			{openModalElementAsButton ? (
				<button
					className={openModalElementClassName}
					onClick={handleOpen}
					style={{ cursor: "pointer", border: "none" }}
				>
					{openModalElementText}
				</button>
			) : (
				<span
					className={openModalElementClassName}
					onClick={handleOpen}
					style={{ cursor: "pointer", border: "none", backgroundColor: "white" }}
				>
					{openModalElementText}
				</span>
			)}

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ zIndex: "1059" }}
			>
				<form onSubmit={handleSubmit(sendContactForm)}>
					<Box sx={style}>
						<Grid container spacing={2} rowSpacing={1}>
							<span className="close-arrow" onClick={handleClose}>
								X
							</span>
							<Grid item xs={12}>
								<h2 className="contact-form-title">Conecta con nosotros</h2>
							</Grid>
							<Grid item xs={12}>
								<div className="form-control-container">
									<label className="form-control-container__label">Nombre</label>
									<input
										type="text"
										className="contacto-form-input"
										{...register("firstName", { required: true })}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className="form-control-container">
									<label className="form-control-container__label">Apellido</label>
									<input
										type="text"
										className="contacto-form-input"
										{...register("lastName", { required: true })}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className="form-control-container">
									<label className="form-control-container__label">Correo electrónico</label>
									<input
										type="email"
										className="contacto-form-input"
										{...register("institutionalEmail", { required: true })}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className="form-control-container">
									<label className="form-control-container__label">Nombre de la institución</label>
									<input
										type="text"
										className="contacto-form-input"
										{...register("institutionName", { required: true })}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className="form-control-container">
									<label className="form-control-container__label">Cargo</label>
									<input
										type="text"
										className="contacto-form-input"
										{...register("job", { required: true })}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className="form-control-container">
									<label className="form-control-container__label">Comentarios</label>
									<input
										type="text"
										className="contacto-form-input"
										{...register("comment", { required: true })}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<FormGroup>
									<Box sx={{ display: "flex", justifyContent: "center", color: "#d3d3d3", alignItems:"center"}}>
										
											
										<Checkbox
											className="contact-form-checkbox-form-input"
											{...register("polityCheck", { required: true })}
											disabled={checkboxStatus}
										/>
											
											
											
											

										<span className="contacto-form-policies">
											<Link to={{pathname: `/${currentLocation}/privacy`}} target='_blank' onClick={() => {scrollToTop(); setCheckboxStatus(false);}}>Acepto las políticas de privacidad </Link>
										</span>
									</Box>
								</FormGroup>
							</Grid>
							<Grid item xs={12} display={"flex"} justifyContent={"center"}>
								<button
									type="submit"
									className={`contacto-from__submit-button ` + (!isValid ? "invalid" : "")}
								>
									Enviar
								</button>
							</Grid>
						</Grid>
					</Box>
				</form>
			</Modal>
		</div>
	);
};

export default ContactForm;
