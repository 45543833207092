const ID_GA_QA = "GTM-NRC4V5M";
const ID_GA_PROD = "GTM-5LQGFZJ";

const local = {
	ANALYTICS_TAG: ID_GA_QA,
	REACT_BACKEND_URL: "http://localhost:3100/dev",
	BACKEND_IA: 'http://localhost:3200/'
};

const dev = {
	ANALYTICS_TAG: ID_GA_QA,
	REACT_BACKEND_URL: "https://x1wnxp5z5a.execute-api.us-east-1.amazonaws.com/dev",
	BACKEND_IA: 'https://7cj4aqehaa.execute-api.us-east-1.amazonaws.com/'
};

const qa = {
	ANALYTICS_TAG: ID_GA_QA,
	REACT_BACKEND_URL: "https://wi07idlht6.execute-api.us-east-1.amazonaws.com/test",
	BACKEND_IA: 'https://7cj4aqehaa.execute-api.us-east-1.amazonaws.com/'
};

const ia = {
	ANALYTICS_TAG: ID_GA_QA,
	REACT_BACKEND_URL: "https://x1wnxp5z5a.execute-api.us-east-1.amazonaws.com/dev",
	BACKEND_IA: 'https://7cj4aqehaa.execute-api.us-east-1.amazonaws.com/'
}

const prod = {
	ANALYTICS_TAG: ID_GA_PROD,
	REACT_BACKEND_URL: "https://od1dhcgwca.execute-api.us-east-1.amazonaws.com/prod",
	BACKEND_IA: 'https://7cj4aqehaa.execute-api.us-east-1.amazonaws.com/'
};

const config = {
	// Add common config values here
	//MAX_ATTACHMENT_SIZE: 5000000,
	// Default to dev if not set
	...(process.env.REACT_APP_ENV === "qa"
		? qa
		: process.env.REACT_APP_ENV === "dev"
		? dev
		: process.env.REACT_APP_ENV === "prod"
		? prod
		: process.env.REACT_APP_ENV === "ia"
		? ia
		: local),
};

export default config;
