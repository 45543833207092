import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { SearchBox } from "../components/SearchBox";
import { ResultsMemorized } from "./Results";
import { memo, useContext, useState } from "react";
import { Filter } from "../components/Filter";
import { Grid } from "@mui/material";
import { Context } from "global/reduxState/store";
import { withAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import {Amplify, API, Auth } from 'aws-amplify';
import awsconfig from '../../aws-config';

Amplify.configure(awsconfig);

//export const Result = withAuthenticator(() => {
export const Result = () => {
	const location = useLocation();
	const { query }: any = queryString.parse(location.search);
	const [queryState, setQuery] = useState(query || "");
	const { n_page }: any = queryString.parse(location.search);

	//filter
	const [{ facets, typeSearch }, dispatch] = useContext(Context);
	const keysFacets = Object.keys(facets);


	return (
		<>
			<SearchBox queryParam={queryState} setQuery={setQuery} />
				{ typeSearch==="keywords" &&
					<>
					<Grid container style={{ margin: "auto", width: "90%", textAlign: "center" }}>
					<Grid item xs={12} md={4}>
						<div className="column">
							{
								facets &&
								// to make it generic we made the map with keys instead of just use .property
								
								keysFacets.map((keyFacet, index) => {
									const filterName = facets[keyFacet][0].name;
									return (
										<Filter
											key={facets[keyFacet][0].uuid}
											title={filterName}
											// @ts-ignore
											fields={facets[keyFacet][0].data}
											showTitle={index === 0}
											dispatch={dispatch}
										/>
									);
								})
							}
						</div>
					</Grid>
					<Grid item xs={12} md={8}>
						{ <ResultsMemorized query={queryState} page={ parseInt(n_page)}/> }
					</Grid>
					</Grid>
					</>
				}
				{ typeSearch==="ia" &&
				<>
					<Grid container style={{ margin: "auto", width: "90%", textAlign: "center", maxWidth: "1200px" }}>
						<Grid item xs={12} md={12}>
							{ <ResultsMemorized query={queryState} page={ parseInt(n_page)}/> }
						</Grid>
					</Grid>
				</>
				}
		</>
	);
//});
};