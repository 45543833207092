type ActualPage = {
	total_pages: number;
	total_results: number;
	size: number;
};
export const paginator = (actualPage: ActualPage, data: any, currentPage = 1) => {
	/**
	 * We remove 1 of the multiplying to get the first Element after the last element of the last one page, example:  (20 * 1) + 1 = 21 [second page]
	 *
	 * @param data
	 * @returns number
	 */
	const getFirstResearcherNumber = (data: any): number => {
		const firstResearcherNumber = size! * (currentPage - 1) + 1;

		if (data?.results?.length === 0) {
			return 0;
		}

		return firstResearcherNumber;
	};

	//pagination logic
	const { total_pages = 0, total_results, size } = actualPage || {};
	const firstResearcherNumber = getFirstResearcherNumber(data);
	const numberOfResultsActualPage = data?.results.length || 0;

	// sometimes we don't get 20 items per page, that's why we use the length ( instead of just use size )
	// to get the exactly number of the last item in the actual page
	// EX: (20 * 0) [ 0 ] + 7 = 7 [example of a single page with only 7 items]
	const lastResearchernumber = size! * (currentPage - 1) + numberOfResultsActualPage!;
	//END pagination logic
	return {
		total_pages,
		total_results,
		firstResearcherNumber,
		lastResearchernumber,
	};
};
