import EquipamentItem from "../components/EquipamentItem";
import { Equipament } from "../../../interfaces/Equipament";
import { useParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import React, { useEffect } from "react";
import { ITabs } from "Result/tabs/shared/interfaces/TabsProps";
import { WrapperResults } from "Result/wrapper-results/WrapperResults";

interface Props extends ITabs {
	data: Equipament[];
}

const EquipamentContainer = ({ data, error, loading, query }: Props) => {
	const { university } = useParams<{ university: string }>();

	useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: "visita",
				pagePath: "equipamentos",
				university: university,
			},
		});
	}, [university]);

	const EquipamentResults = (): JSX.Element[] => {
		return data.map((equipamentItem: Equipament, index: number) => {
			return (
				<React.Fragment key={equipamentItem._meta.id}>
					<EquipamentItem {...equipamentItem} />
				</React.Fragment>
			);
		});
	};

	return (
		<WrapperResults component={EquipamentResults} data={data} error={error} query={query} loading={loading} />
	);
};

export default EquipamentContainer;
