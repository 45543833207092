import { DropDownWithoutIcon } from "global/components/DropDownWithoutIcon"

import dataDivulgation from '../../../data/policies/DataDivulgation.json';


export const DataDivulgation = () => {

    const title = dataDivulgation.title

    return (
        <DropDownWithoutIcon title={title}>
            <div className="privacy-grid-information">
                {   
                    (
						<>
                            {
                                dataDivulgation.content.map((content, index)=> (
                                    
                                    <span key={index}>
                                        <p>{content.text}</p>
                                        <ul>
                                        {
                                            content.subsubcontent?.map((subsubcontent, index) => (
                                                <div>
                                                    <li  key={subsubcontent + index.toString()}>
                                                        
                                                        {subsubcontent.description}
                                                        
                                                   </li>

                                                   {index + 1 !== content.subsubcontent.length && <br></br> }
                                                </div>
                                                    
                                                
                                                
                                            ))
                                        }
                                        
                                        </ul>
                                        <p>{content.ps}</p>
                                    </span>
                                        
                            

                                ))
                            }

                        </>
                    )


                }
            </div>
           

            
        </DropDownWithoutIcon>
    )


}