import styles from "./styles/TabsMenu.module.css";
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../../../aws-config';
import useGetFirstUrlParam from "hooks/useGetFirstUrlParam";
import { UserContext, Login } from './UserContext'

Amplify.configure(awsconfig);


interface Props {
    url: string;
    folder: string;
}

export const MenuLogin = ({ url, folder }: Props) => {
    let [user, setUser] = useState(null);
    const firstParam = useGetFirstUrlParam();
    const history = useHistory();

    const Login = useContext(UserContext);

    useEffect(() => {
        if (Login.user === null) {
            console.log('viene null');
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    Login.user=user;
                    setUser(user);
                    console.log("++++++>" + user);

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    const handleLogout = async () => {
        try {
            Auth.signOut().then(() => { console.log("Sesion terminada") });
            Login.user = null;
            history.push(`/${firstParam}/`);
        } catch (error) {
            console.error("Ha ocurrido un error: ", error)
        }
    };
    return (
        <>
            <UserContext.Provider value={Login}>
                {console.log("user:" + Login)}
                {console.log("user1:" + Login.user)}
                {console.log("user3:" + JSON.stringify(Login.user))}
                <div className={styles["menu-category"]}>
                    {Login.user ? <>
                        <button name="" onClick={() => { history.push(`/${firstParam}/`); }}>Home</button>
                        <button name="" onClick={() => { handleLogout() }} >Sign out</button>
                    </>

                        : <>
                            <button name="" onClick={() => { history.push(`/${firstParam}/ingresar`); }} >Iniciar Sesi&oacute;n</button>
                            <button name="" onClick={() => { history.push(`/${firstParam}/registro`); }} >Registrarse</button>
                        </>}
                </div>
            </UserContext.Provider>

        </>
    )
}


export const MenuLogin2 = ({ url, folder }: Props) => {
    let [user, setUser] = useState(null)
    const firstParam = useGetFirstUrlParam();
    const history = useHistory();
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount(count + 1);
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
                console.log("++++++>" + user);

            })
            .catch((error) => {
                setUser(null);
                console.log(error);
            });
    }, []);



    const handleLogout = async () => {
        setCount(count + 1);
        try {
            Auth.signOut().then(() => { console.log("Sesion terminada") });
            setUser(null);
            history.push(`/${firstParam}/`);
        } catch (error) {
            console.error("Ha ocurrido un error: ", error)
        }
    };

    return (
        <>
            <div className={styles["menu-category"]}>
                <Botones user={user} handleLogout={handleLogout} />

            </div>
        </>
    )
}

interface PropsBoton {
    user: any;
    handleLogout: () => void;
}
export const Botones = ({ user, handleLogout }: PropsBoton) => {
    const history = useHistory();
    const firstParam = useGetFirstUrlParam();

    return (
        <>
            <div className={styles["menu-category"]}>
                {user ? <>
                    <button name="" onClick={() => { history.push(`/${firstParam}/`); }}>Home</button>
                    <button name="" onClick={() => { handleLogout() }} >Sign out</button>
                </>

                    : <>
                        <button name="" onClick={() => { history.push(`/${firstParam}/ingresar`); }} >Iniciar Sesi&oacute;n</button>
                        <button name="" onClick={() => { history.push(`/${firstParam}/registro`); }} >Registrarse</button>
                    </>}



            </div>
        </>
    )
}