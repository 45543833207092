import { DropDownWithoutIcon } from "global/components/DropDownWithoutIcon"

import dataInterpretation from '../../../data/policies/InterpretationAndDefinitions.json';


export const InterpretationAndDefinitions = () => {

    const title = dataInterpretation.title

    return (
        <DropDownWithoutIcon title={title}>
            <div className="privacy-grid-information">
                {   
                    (
						<>
                            {
                                dataInterpretation.content.map((content, index)=> (
                                    
                                    <span key={index}>
                                        <p className="subtitle-politica" key={index}>{content.subcontent}</p>
                                        <p>{content.text}</p>
                                        <ul>
                                        {
                                            content.subsubcontent?.map((subsubcontent, index) => (
                                           
                                                    <li  key={subsubcontent + index.toString()}>
                                                        <strong> {subsubcontent.term} </strong>
                                                         {subsubcontent.description}
                                                    </li>
                                                
                                                
                                            ))
                                        }
                                        </ul>
                        
                                    </span>
                                        
                            

                                ))
                            }

                        </>
                    )


                }
            </div>
           

            
        </DropDownWithoutIcon>
    )


}