import { CenterItem } from "../components/CenterItem";
import { Centro } from "../../../interfaces/Centro";
import { useParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { WrapperResults } from "Result/wrapper-results/WrapperResults";
import { ITabs } from "Result/tabs/shared/interfaces/TabsProps";

interface Props extends ITabs {
	data: Centro[];
}

export const CenterContainer = ({ data, error, loading, query }: Props): JSX.Element => {
	const { university } = useParams<{ university: string }>();

	useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: "visita",
				pagePath: "centros",
				university: university,
			},
		});
	}, [university]);

	const CenterResults = (): JSX.Element[] => {
		return data.map((centerItem: Centro, index: number) => {
			return (
				<div key={centerItem._meta.id}>
					{index > 0 && <hr />}
					<CenterItem {...centerItem} />
				</div>
			);
		});
	};

	return (
		<>
			<WrapperResults component={CenterResults} data={data} error={error} query={query} loading={loading} />
		</>
	);
};
