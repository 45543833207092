import "./header.css";
import { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useGetFirstUrlParam from "../../hooks/useGetFirstUrlParam";
import { MenuLogin, MenuLogin2, AccountMenu } from "../../auth/login";
import useSQL from "hooks/useSQL";

import { axiosInstance } from "global/utils/axios";
import { getWithoutExpiry, setWithoutExpiry } from "global/utils/localStorageUtils";


const Header = (props) => {
	const folder = useGetFirstUrlParam();
	const { pathname } = props.location;

	const actualPath = pathname.replace(/\//g, "");

	let actualCache = getWithoutExpiry('lastUpdate');

	const [lastUpdate, setLastUpdate] = useState((actualCache && actualCache[actualPath] && actualCache[actualPath].ttl > new Date().getTime())
		? actualCache[actualPath].date : '');

	const [isSessionValidated, setSessionValidated] = useState(false);

	useEffect(() => {

		if (!actualCache) {
			actualCache = {};
		}

		if (!actualCache.hasOwnProperty(actualPath) || (actualCache.hasOwnProperty(actualPath) && actualCache[actualPath].ttl < new Date().getTime())) {

			const d = new Date();

			d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));

			axiosInstance
				.get(`last-update${pathname}`)
				.then(({ data }) => {



					let newObj = {
						ttl: d.getTime(),
						date: data.response.lastDate
					}
					actualCache[actualPath] = newObj;
					setWithoutExpiry('lastUpdate', actualCache);
					setLastUpdate(data.response.lastDate);
				})
				.catch((error) => {
					console.error(error)

					let newObj = {
						ttl: d.getTime(),
						date: 'julio 2022'
					}
					actualCache[actualPath] = newObj;
					setLastUpdate(newObj.date);
				});
		} else {

			setLastUpdate(actualCache[actualPath].date);
		}


	}, [])


	return (
		<div className="header-container">
			<header>
				<div className="header-right-login"><AccountMenu  url={actualPath} folder={folder} /></div>
				<div className="header-right">
					{/*
					<div>
						<MenuLogin url={actualPath} folder={folder} />
							
					</div>*/}
					<div>
						{folder !== "caps" && (
							<div className="hubtec-logo-container">
								<img
									src="/images/udd/logo_hubtec_powered_by.png"
									alt="Powered by logo"
									className="header-hubtec-logo"
								/>
							</div>
						)}
					</div>


				</div>
				<div className="header-left">
					<div className="header-left-child">
						<div className="university-header-logo">
							<Link to={`/${folder}/`}>
								<img src={`/images/${folder}/logo.png`} alt={`Logo ${folder}`} />
							</Link>
						</div>
						{pathname === `/${folder}/` &&
							lastUpdate !== '' &&
							(
								<div className="header-information">
									<p className="orange-text">Portafolio de Capacidades de I+D+i</p>
									{
										<p className="version-text">Actualización datos {lastUpdate}</p>

									}
								</div>
							)}
						{lastUpdate === '' && <span>Cargando ...</span>}
					</div>

				</div>
			</header>
		</div>
	);
};
export const HeaderWithRouter = withRouter(Header);
